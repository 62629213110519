import React, { useEffect, useReducer, useState } from "react";
import { uuid } from "uuidv4";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FindRestaurant from "./FindRestaurant";
import RestaurantDetails from "./RestaurantDetails";
import PhotoUploader from "./PhotoUploader/PhotoUploader";
import ContactInfo from "./ContactInfo";
import Summary from "./Summary";
import Finish from "./Finish";
import { createRestaurant } from "./RestaurantCreator";
import Api from "../api/index";
import * as config from "../config";
import { VF_COLORS } from "../config";
import vflogo from "../assets/vflogo.png";
import "./Stepper.css";
import ReCAPTCHA from "react-google-recaptcha";
import { validateTime } from "@mui/lab/internal/pickers/time-utils";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	firstStepContainer: {
		[theme.breakpoints.up("smPhone")]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		firstFormStepContainer: {
			width: "100%",
		},
		[theme.breakpoints.up("lgPhone")]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		[theme.breakpoints.up("ipad")]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		[theme.breakpoints.up("smPc")]: {
			display: "block",
		},
		[theme.breakpoints.down("lgPc")]: {
			display: "block",
		},
	},

	pageContainer: {
		paddingTop: "50px",
	},
	findRestaurant: {
		width: "100%",
	},
	vflogo: {
		[theme.breakpoints.up("smPc")]: {
			left: "5%",
		},
		[theme.breakpoints.up("lgPc")]: {
			left: "5%",
		},

		height: "200px",
		width: "170px",
		zIndex: "3",
		position: "relative",
		objectFit: "cover",
		backgroundRepeat: "no-repeat",
		top: "5%",
	},
	buttonDisabled: {
		display: "none",
	},
	button: {
		backgroundColor: VF_COLORS.primary,
		color: "white",
		width: "120px",
		height: "70px",
	},
	firstStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1200")]: {
			minWidth: "100px",
		},
		borderRadius: "20px 0px 0px 0px",
		height: "100px",
		backgroundColor: "#84B858",
		paddingRight: "30px",
	},
	prevStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		height: "100px",
		backgroundColor: "#84B858 ",
		paddingRight: "30px",
		boxShadow: "none",
	},
	selectedStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		borderRadius: "20px 20px 0px 0px",
		height: "100px",
		backgroundColor: "white",
		marginLeft: "-20px",
		zIndex: 2,
	},
	nextStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		height: "100px",
		backgroundColor: "#E4E4E4 ",
		paddingLeft: "30px",
		marginLeft: "-15px",
		zIndex: 1,
	},
	completedStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		height: "100px",
		backgroundColor: "#84B858 ",
		zIndex: 1,
		boxShadow: "none",
	},
	notCompletedStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		height: "100px",
	},
	lastStep: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		[theme.breakpoints.up("1201")]: {
			minWidth: "100px",
		},
		borderRadius: "0px 20px 0px 0px",
		height: "100px",
		backgroundColor: "#84B858",
	},
	lastStepIsNext: {
		[theme.breakpoints.down("500")]: {
			minWidth: "20px",
		},
		[theme.breakpoints.between("500", "700")]: {
			minWidth: "40px",
		},
		[theme.breakpoints.between("700", "1000")]: {
			minWidth: "80px",
		},
		[theme.breakpoints.between("1000", "1201")]: {
			minWidth: "100px",
		},
		backgroundColor: VF_COLORS.primary,
		borderRadius: "0px 20px 0px 0px",
		height: "100px",
		paddingLeft: "30px",
		marginLeft: "-15px",
	},

	progressButton: {
		height: "100px",
		backgroundColor: "#84B858 ",
	},

	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	formStep: {
		paddingHorizontal: 500,
		display: "flex",
		justifyContent: "center",
		marginTop: "-15px",
	},

	title: {
		[theme.breakpoints.up("smPhone")]: {
			fontSize: 30,
			textAlign: "center",
		},
		[theme.breakpoints.up("lgPhone")]: {
			fontSize: 40,
			textAlign: "center",
		},
		[theme.breakpoints.up("ipad")]: {
			fontSize: 45,
			textAlign: "center",
		},
		[theme.breakpoints.up("smPc")]: {
			fontSize: 50,
			paddingLeft: "5%",
			textAlign: "left",
		},
		[theme.breakpoints.up("lgPc")]: {
			fontSize: 55,
			paddingLeft: "5%",
			textAlign: "left",
		},
		textAlign: "left",
		color: config.VF_COLORS.primary,
		display: "flex",
		justifyContent: "flex-start",
		paddingTop: "2%",
		fontWeight: "bold",
		letterSpacing: "0.005em",
	},
	description: {
		[theme.breakpoints.up("smPhone")]: {
			fontSize: 15,
			textAlign: "center",
		},
		[theme.breakpoints.down("lgPhone")]: {
			fontSize: 20,
			textAlign: "center",
		},
		[theme.breakpoints.up("ipad")]: {
			fontSize: 25,
			textAlign: "center",
		},
		[theme.breakpoints.up("smPc")]: {
			fontSize: 30,
			paddingLeft: "5%",
			textAlign: "left",
		},
		[theme.breakpoints.up("lgPc")]: {
			fontSize: 35,
			paddingLeft: "5%",
			textAlign: "left",
		},
		justifyContent: "flex-start",
		textAlign: "left",
		color: VF_COLORS.secondary,
		marginHorizontal: 20,
		lineHeight: 1.2,
		letterSpacing: "0.005em",
	},
	container: {
		position: "relative",
	},

	buttons: {
		justifyContent: "space-between",
		display: "flex",
		width: "100vw",
		position: "fixed",
		top: "85vh",
		zIndex: 5,
	},

	backButtonContainer: {
		position: "fixed",
		left: 0,
		bottom: 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		maxWidth: "120px",
		zIndex: 5,
	},
	nextButtonContainer: {
		position: "fixed",
		right: 0,
		bottom: 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		maxWidth: "120px",
		zIndex: 5,
	},
	nextButtonDisplay: {
		display: "none",
	},

	testString: {
		justifyContent: "center",
		textAlign: "center",
		display: "flex",
	},

	stepperContainer: {
		flex: 1,
		width: "90vw",
		flexDirection: "row",
		justifyContent: "flex-start",
		padding: 0,
		alignItems: "center",
	},

	step_label_current: {
		[theme.breakpoints.down("1200")]: {
			fontSize: "20px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},

		[theme.breakpoints.up("1200")]: {
			fontSize: "15px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},
		[theme.breakpoints.up("1450")]: {
			fontSize: "20px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},

		[theme.breakpoints.up("1800")]: {
			fontSize: "28px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},
	},
	step_label_prev: {
		[theme.breakpoints.down("1200")]: {
			fontSize: "20px",
			marginBottom: "20px",
			color: "white",
			textTransform: "none",
		},
		[theme.breakpoints.up("1200")]: {
			fontSize: "15px",
			marginBottom: "20px",
			color: "white",
			textTransform: "none",
		},

		[theme.breakpoints.up("1450")]: {
			fontSize: "20px",
			marginBottom: "20px",
			color: "white",
			textTransform: "none",
		},

		[theme.breakpoints.up("1800")]: {
			fontSize: "28px",
			marginBottom: "20px",
			color: "#white",
			textTransform: "none",
		},
	},
	step_label_next: {
		[theme.breakpoints.down("1200")]: {
			fontSize: "20px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},
		[theme.breakpoints.up("1200")]: {
			fontSize: "15px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},

		[theme.breakpoints.up("1450")]: {
			fontSize: "20px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},

		[theme.breakpoints.up("1800")]: {
			fontSize: "28px",
			marginBottom: "20px",
			color: "#84B858",
			textTransform: "none",
		},
	},
}));

function getSteps() {
	return ["Start", "Restaurant information", "Upload images", "Contact Info", "Summary", "Finish"];
}

export default function VeganFriendlyForm(props) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(config.test ? config.testStep : 0);
	const [restaurant, setRestaurant] = React.useState(null);
	const [restaurantId, setRestaurantId] = React.useState(-1);
	const [serverErr, setServerErr] = useState(false);
	const [alert, setAlert] = useState({
		alertErr: {
			logo: null,
			categories: null,
			time: null,
			menuPics: null,
			foodPics: null,
		},
	});
	const [captcha, setCaptcha] = useState("");
	function onChangeReCaptcha(value) {
		setCaptcha(value);
	}
	function onExpiredReCaptcha() {
		setCaptcha("");
	}
	const stepperForm = React.useRef(null);
	const [loader, setLoader] = useState(false);

	const steps = getSteps();

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	useEffect(async () => {
		await Api.login();
	}, []);

	const [formIsTouched, setFormIsTouched] = useState(false);

	const [formInput, setFormInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
		restaurant_name: "",
		country: 'US',
		address: "",
		timezone: 0,
		state: 'NY',
		location: null,
		phone_number: "",
		categories: [],
		schedule: [
			...[1, 2, 3, 4, 5, 6, 0].map((day) => ({
				from_day: day,
				to_day: day,
				shifts: [{ active: false }],
			})),
		],
		alwaysOpen: false,
		checkDeliveroo: false,
		checkJustEat: false,
		checkUberEats: false,
		checkRestaurant: false,
		deliverooURL: "",
		uberEatsURL: "",
		justEatURL: "",
		restaurantURL: "",
		deliverooURLErorr: "",
		uberEatsURLEror: "",
		justEatURLError: "",
		restaurantURLError: "",
		glutenFreeOptions: false,
		vegan: false,
		wheelchairAccessibility: false,
		description: "",
		logo: null,
		foodPics: [],
		menuPics: [],
		contactName: "",
		contactNumber: "",
		errorMessage: null,
		restaurantNameError: null,
		scheduleError: null,
		phoneNumberError: null,
		logoError: null,
		contactNameError: null,
		contactNumberError: null,
		roleError: null,
		emailError: null,
		foodPicsErr: null,
		menuPicsErr: null,
		addressError: null,
		descriptionError: null,
		resaturantCategoriesError: null,
		discountDescription: "",
		discountTerms: "",
		role: "",
		email: "",
		source: "landing-page",
	});

	const formInputInit = () => {
		console.log("here at reset");
		if (restaurantId !== -1) {
			console.log("here at rest id -1");
			setRestaurantId(-1);
			setRestaurant(null);
			handleInput("restaurant_name", "");
			handleInput('country', 'US')
			handleInput("address", "");
			handleInput("phone_number", "");
			handleInput("timezone", 0);
			handleInput("state", 'NY');

			handleInput("schedule", [
				...[1, 2, 3, 4, 5, 6, 0].map((day) => ({
					from_day: day,
					to_day: day,
					shifts: [],
				})),
			]);
		}
	};

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	const validateContact = () => {
		const emailError = !formInput.email.trim().length ? "Email is required " : !validateEmail(formInput.email) ? "Email is invalid" : null;

		const contactNameError = !formInput.contactName.trim().length ? "Contact name is required" : null;
		const roleError = !formInput.role.trim().length ? "Contact role is required" : null;
		const contactNumberError = !formInput.contactNumber.trim().length ? "Contact number is required" : null;

		setFormInput({
			contactNameError,
			roleError,
			contactNumberError,
			emailError,
		});
		return !contactNameError && !roleError && !contactNumberError && !emailError;
	};

	const validateOpeningTimes = () => {
		if (formInput.alwaysOpen) {
			handleInput({
				scheduleError: null,
			});
			setAlert((prevState) => {
				let alertErr = Object.assign({}, prevState.alertErr);
				alertErr.time = false;
				return { alertErr };
			});
			return true;
		}
		if (
			formInput.schedule[0].shifts.length === 0 &&
			formInput.schedule[1].shifts.length === 0 &&
			formInput.schedule[2].shifts.length === 0 &&
			formInput.schedule[3].shifts.length === 0 &&
			formInput.schedule[4].shifts.length === 0 &&
			formInput.schedule[5].shifts.length === 0 &&
			formInput.schedule[6].shifts.length === 0
		) {
			const scheduleError = "Fill in your opening hours";
			setFormInput({
				scheduleError,
			});
			console.log("empty shifts");
			return false;
		} else if (
			formInput.schedule[0].shifts.length > 0 &&
			formInput.schedule[0].shifts[0].active === false &&
			formInput.schedule[1].shifts.length > 0 &&
			formInput.schedule[1].shifts[0].active === false &&
			formInput.schedule[2].shifts.length > 0 &&
			formInput.schedule[2].shifts[0].active === false &&
			formInput.schedule[3].shifts.length > 0 &&
			formInput.schedule[3].shifts[0].active === false &&
			formInput.schedule[4].shifts.length > 0 &&
			formInput.schedule[4].shifts[0].active === false &&
			formInput.schedule[5].shifts.length > 0 &&
			formInput.schedule[5].shifts[0].active === false &&
			formInput.schedule[6].shifts.length > 0 &&
			formInput.schedule[6].shifts[0].active === false
		) {
			const scheduleError = "Fill in your opening hours";
			setFormInput({
				scheduleError,
			});
			console.log("top statment");
			return false;
		} else {
			for (let dayShift of formInput.schedule) {
				for (let i in dayShift.shifts) {
					if (!dayShift.shifts[i].hasOwnProperty("from") && !dayShift.shifts[i].hasOwnProperty("to") && dayShift.shifts[i].active === true) {
						const scheduleError = "Fill in your opening hours";
						console.log("first statemtnt");
						setFormInput({
							scheduleError,
						});
						return false;
					} else if (dayShift.shifts[i].hasOwnProperty("from") && dayShift.shifts[i].hasOwnProperty("to") && (dayShift.shifts[i].from === "Invalid date" || dayShift.shifts[i].to === "Invalid date") && dayShift.shifts[0].active === true) {
						const scheduleError = "Fill in your opening hours";
						console.log("second statemtnt" + JSON.stringify(dayShift.shifts[i]));

						setFormInput({
							scheduleError,
						});
						return false;
					} else if ((dayShift.shifts[i].hasOwnProperty("from") && dayShift.shifts[i].from === undefined && dayShift.shifts[i].active === true) || (dayShift.shifts[i].hasOwnProperty("to") && dayShift.shifts[i].to === undefined && dayShift.shifts[i].active === true)) {
						const scheduleError = "Fill in your opening hours";
						console.log("third statemtnt" + JSON.stringify(dayShift.shifts[i]));

						setFormInput({
							scheduleError,
						});
						return false;
					}
				}
			}
			const scheduleError = null;
			setFormInput({
				scheduleError,
			});
			console.log(formInput.schedule);
			console.log("return true out of for loop" + JSON.stringify(formInput.schedule));
			setAlert((prevState) => {
				let alertErr = Object.assign({}, prevState.alertErr);
				alertErr.time = false;
				return { alertErr };
			});
			return true;
		}
	};

	const validateRestaurantDetails = () => {
		const restaurantNameError = !formInput.restaurant_name.trim().length ? "Restaurant name is required" : null;
		const addressError = formInput.address.trim().length < 5 ? "Please enter a valid address" : null;
		const phoneNumberError = formInput.phone_number.trim().length === 0 ? "Please enter a valid phone number" : null;
		const logoError = formInput.logo == null ? "Please upload resaturant logo" : null;
		const resaturantCategoriesError = formInput.categories.length === 0 || formInput.categories[0].length === 0 ? "Categories are required" : null;
		const descriptionError = !formInput.description.trim().length ? "Description is required" : null;
		const deliverooURLError = !formInput.deliverooURL.trim().length && formInput.checkDeliveroo ? "URL is required" : null;
		const uberEatsURLError = !formInput.uberEatsURL.trim().length && formInput.checkUberEats ? "URL is required" : null;
		const justEatURLError = !formInput.justEatURL.trim().length && formInput.checkJustEat ? "URL is required" : null;
		const restaurantURLError = !formInput.restaurantURL.trim().length && formInput.checkRestaurant ? "URL is required" : null;

		setFormInput({
			restaurantNameError,
			addressError,
			phoneNumberError,
			logoError,
			descriptionError,
			resaturantCategoriesError,
			deliverooURLError,
			uberEatsURLError,
			justEatURLError,
			restaurantURLError,
		});

		return !restaurantNameError && !addressError && !phoneNumberError && !descriptionError && !deliverooURLError && !uberEatsURLError && !justEatURLError && !restaurantURLError;
	};

	const validatePhotoUploader = () => {
		const foodPicsErr = !formInput.foodPics.length ? "Cover photos are mandatory" : null;
		const menuPicsErr = !formInput.menuPics.length ? "Menu photos are mandatory" : null;
		setFormInput({ menuPicsErr, foodPicsErr });
		return !foodPicsErr && !menuPicsErr;
	};

	const handleInput = async (key, value) => {
		setFormInput({ [key]: value });
	};

	const handleStepInput = (stepInput) => {
		for (let key of Object.keys) {
			setFormInput({ [key]: stepInput[key] });
		}
	};

	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<FindRestaurant
						setRestaurantId={(id) => {
							setRestaurantId(id);
							setActiveStep(1);
						}}
						errorMessage={formInput.errorMessage}
						undoErrorMessage={undoErrorMessage}
						onClickNoPlaceButton={handleNext}
						className={classes.findRestaurant}
						handleInput={handleInput}
						captcha={captcha}
						formIsTouched={formIsTouched}
						setFormIsTouched={setFormIsTouched}
						formInputInit={formInputInit}
					/>
				);
			case 1:
				return <RestaurantDetails restaurantId={restaurantId} handleInput={handleInput} handleStepInput={(stepObject) => handleStepInput(stepObject)} formInput={formInput} alert={alert} setAlert={setAlert} formIsTouched={formIsTouched} setFormIsTouched={setFormIsTouched} />;
			case 2:
				return <PhotoUploader handleInput={handleInput} formInput={formInput} alert={alert} />;
			case 3:
				return <ContactInfo handleInput={handleInput} formInput={formInput} errorMessage={formInput.errorMessage} loader={loader} validateContact={validateContact} />;
			case 4:
				return <Summary restaurant={restaurant} goBack={handleBack} submitRestaurant={submitRestaurant} formInput={formInput} />;
			case 5:
				return <Finish error={serverErr} />;
			default:
				break;
		}
	}
	if (activeStep === 0) {
		props.setBackgroundImageDisplay(true);
	} else {
		props.setBackgroundImageDisplay(false);
	}

	const handleNext = async () => {
		if (activeStep === 0) {
			undoErrorMessage();

			setActiveStep((prevActiveStep) => prevActiveStep + 1);

			setFormInput({ errorMessage: "Please select your business" });
		} else if (activeStep === 1) {
			undoErrorMessage();
			const restValid = validateRestaurantDetails();
			const timeValid = true;//validateOpeningTimes();
			console.log(timeValid);
			timeValid && restValid && setActiveStep((prevActiveStep) => prevActiveStep + 1);
			if (!restValid || !timeValid) {
				if (formInput.categories.length === 0 || formInput.categories[0].length === 0) {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.categories = true;
						return { alertErr };
					});

					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
				} else {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.categories = false;
						return { alertErr };
					});
				}

				if (formInput.logo === null) {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.logo = true;
						return { alertErr };
					});
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
				} else {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.logo = false;
						return { alertErr };
					});
				}
				if (!timeValid) {
					console.log("error should pop");
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.time = true;
						return { alertErr };
					});
				} else {
					console.log("error should go away");
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.time = false;
						return { alertErr };
					});
				}
			}
		} else if (activeStep === 2) {
			undoErrorMessage();
			const valid = true;//validatePhotoUploader();
			valid && setActiveStep((prevActiveStep) => prevActiveStep + 1);
			if (!valid) {
				if (!formInput.foodPics.length) {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.foodPics = true;
						return { alertErr };
					});
				} else {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.foodPics = false;
						return { alertErr };
					});
				}
				if (!formInput.menuPics.length) {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.menuPics = true;
						return { alertErr };
					});
				} else {
					setAlert((prevState) => {
						let alertErr = Object.assign({}, prevState.alertErr);
						alertErr.menuPics = false;
						return { alertErr };
					});
				}
			}
		} else if (activeStep === 3) {
			const valid = true;//validateContact();
			if (valid) {
				undoErrorMessage();
				setLoader(true);
				const restaurant = await createRestaurant(formInput);
				setRestaurant(restaurant);
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				setLoader(false);
			} else {
				setFormInput({ errorMessage: "Please fill in all data" });
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const submitRestaurant = async () => {
		const response = await Api.submitRestaurant(restaurant);

		if (response && response.data) {
			alert("restaurant submitted successfully!");
			setActiveStep(0);
			setRestaurantId(-1);
		} else {
			alert("problem with submitting form");
			setActiveStep(3);
		}
	};

	const undoErrorMessage = () => setFormInput({ errorMessage: null });

	const handleBack = () => {
		undoErrorMessage();
		if (activeStep > 0) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}

		if (activeStep === 0) {
			setActiveStep(0);
		}
	};
	const goToStep = (newStep) => {
		undoErrorMessage();
		if (newStep > 0) {
			setActiveStep(newStep);
		}

		if (newStep === 0) {
			setActiveStep(0);
		}
	};
	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<div className={classes.pageContainer}>
			<div className={activeStep === 0 ? classes.firstStepContainer : classes.root}>
				<div className={classes.stepperContainer}>
					{activeStep === 0 ? null : (
						<Stepper className={classes.stepperContainer} activeStep={activeStep} alternativeLabel>
							{steps.map((label, index) => (
								<Button
									key={index}
									variant="contained"
									className={
										index == activeStep
											? classes.selectedStep
											: index == activeStep - 1 && activeStep >= 2
											? classes.prevStep
											: index == activeStep + 1 && index !== 5
											? classes.nextStep
											: activeStep > 0 && index < 1
											? classes.firstStep
											: index == 5 && activeStep == 4
											? classes.lastStepIsNext
											: index == 5 && activeStep < 4
											? classes.lastStep
											: activeStep > 0 && index < activeStep - 1
											? classes.completedStep
											: activeStep > 0 && index > activeStep + 1
											? classes.notCompletedStep
											: null
									}
									onClick={() => goToStep(index)}>
									<StepLabel classes={index == activeStep ? { label: classes.step_label_current } : activeStep > 0 && index < activeStep ? { label: classes.step_label_prev } : activeStep > 0 && index > activeStep ? { label: classes.step_label_next } : null}>
										{width <= 1199 ? `${index + 1}` : `${index + 1}` + " " + `${label}`}
									</StepLabel>
								</Button>
							))}
						</Stepper>
					)}
				</div>
				{activeStep === 0 ? (
					<div className={activeStep === 0 ? classes.vflogo : null}>
						<img src={vflogo} height="200px" width="170px"></img>
					</div>
				) : null}
				{activeStep === 0 ? (
					<>
						<div className={classes.container}>
							<label className={classes.title}>Welcome to the Vegan Friendly family!</label>
							{config.test && <label className={classes.testString}></label>}
							{activeStep === 0 && (
								<>
									<h2 className={classes.description}>
										Now that you're officially certified Vegan Friendly,
										<br />
										we'd love to feature you on our app
										<br />
										and introduce you to our thousands of users.
										<br />
										Please fill out the form below for our team to review <br />
										and add you to the app.
									</h2>
									{/* <p style={{ position: "fixed", bottom: 0, right: 5 }}> PROD SERVER | version : 1.1.8</p> */}
								</>
							)}

							<br />
							<div
								style={
									width > 1099
										? {
												display: "flex",
												paddingLeft: "5%",
										  }
										: {
												display: "flex",
												justifyContent: "center",
										  }
								}>
								<ReCAPTCHA style={{ position: "relative" }} sitekey={config.siteKey} onChange={onChangeReCaptcha} onExpired={onExpiredReCaptcha} />
							</div>
						</div>
						<br />
					</>
				) : null}
				<div style={{ width: "100%" }}>
					{activeStep === steps.length ? (
						<div>
							<Typography className={classes.instructions}>All steps completed</Typography>
							<Button onClick={handleReset}>Reset</Button>
						</div>
					) : (
						<div className={activeStep !== 0 ? classes.formStep : classes.firstFormStepContainer}>
							<Typography component={"div"} className={activeStep !== 0 ? classes.formStep : classes.firstFormStepContainer}>
								{getStepContent(activeStep)}
							</Typography>
						</div>
					)}
				</div>
				<div className={width > 1199 ? classes.buttons : null} style={width <= 1199 ? { display: "flex", marginTop: "30px", marginBottom: "30px" } : null}>
					<div className={classes.backButtonContainer} style={width <= 1199 ? { position: "relative" } : null}>
						<Button
							style={
								width <= 1199
									? {
											borderRadius: "15px 0px 0px 15px",
											boxShadow: "0px 3px 6px #00000029",
									  }
									: {
											borderRadius: "0px 15px 15px 0px",
											boxShadow: "0px 3px 6px #00000029",
									  }
							}
							disabled={activeStep === 0 || loader}
							onClick={handleBack}
							className={activeStep === 0 || activeStep === 5 ? classes.buttonDisabled : classes.button}>
							Back
						</Button>
					</div>
					<div className={activeStep === 0 ? classes.nextButtonDisplay : classes.nextButtonContainer} style={width <= 1199 ? { position: "relative" } : null}>
						<Button
							disabled={loader}
							style={
								width <= 1199
									? {
											borderRadius: "0px 15px 15px 0px",
											boxShadow: "0px 3px 6px #00000029",
									  }
									: {
											borderRadius: "15px 0px 0px 15px",
											boxShadow: "0px 3px 6px #00000029",
									  }
							}
							variant="contained"
							className={activeStep === 5 ? classes.buttonDisabled : classes.button}
							onClick={async () => {
								if (restaurant && activeStep === 4) {
									const submitted = await Api.submitRestaurant(restaurant);

									if (submitted.response !== undefined || submitted.request.length > 0 || submitted.message !== undefined) {
										console.log("res:" + JSON.stringify(submitted.response) + "req:" + JSON.stringify(submitted.request) + "msg:" + JSON.stringify(submitted.message));
										setServerErr(true);
										handleNext();
									} else {
										const email = await Api.sendEmail(restaurant);
										setServerErr(false);
										handleNext();
									}
								} else {
									handleNext();
								}
							}}>
							{activeStep === 4 ? "Finish" : "Next"}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
