import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import Main from "./components/Main";
import bgImage from "./assets/bgImage.jpg";
import bgImageWO from "./assets/bgImageWO.jpg";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const theme = createTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      smPhone: 0,
      lgPhone: 300,
      ipad: 600,
      smPc: 1100,
      mdPc: 1200,
      lgPc: 1700,
    },
  },
  palette: {
    secondary: {
      main: "#85B858",
    },
    primary: {
      main: "#c8678d",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: "white",
    fontFamily: "roboto",
    zIndex: "1",
  },
  backgroundPhoto: {
    backgroundImage: `url(${bgImage})`,
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: "100%",
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "hidden",
    zIndex: "0",
    margin: "0",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  backgroundPhotoWO: {
    backgroundImage: `url(${bgImageWO})`,
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: "100%",
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "hidden",
    zIndex: "0",
    margin: "0",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export default function App() {
  const classes = useStyles();
  const [backgroundImageDisplay, setBackgroundImageDisplay] = useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <div>
        {backgroundImageDisplay ? (
          <div className={classes.backgroundPhoto}></div>
        ) : (
          <div className={classes.backgroundPhotoWO}></div>
        )}

        <div className={classes.root}>
          <CssBaseline />
          <Main
            style={{ height: 100 }}
            setBackgroundImageDisplay={setBackgroundImageDisplay}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
}
