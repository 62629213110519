import React, { useState, useEffect } from "react";
import { VF_COLORS } from "../../config";
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = {
  closeButton: {
    border: "none",
    backgroundColor: "inherit",
    padding: "14px",
    fontSize: "16px",
    cursor: "pointer",
    display: "inline-block",
    "&:hover": {
      backgroundColor: VF_COLORS.primary,
    },
  },
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function PhotoContainer(props) {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  const { preview, id, type, onChangePhotos, name } = props;

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const { height, width } = useWindowDimensions();

  const changeUiByViewPortWidth = () => {
    if (width > 1199) {
      return (
        <Card
          style={{
            display: "flex",
            maxHeight: 110,
            minHeight: 110,
            minWidth: 375,
            maxWidth: 375,
            backgroundColor: "#F9F9F9",
            marginRight: "12px",
            marginBottom: "12px",
          }}
        >
          <CardMedia
            component="img"
            image={preview}
            style={{
              height: "90px",
              width: "90px",
              position: "relative",
              top: "10px",
              left: "10px",
              backgroundSize: "contain",
            }}
          />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "225px",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "0" }}>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginLeft: "20px", marginTop: "30px" }}
              >
                {name}
              </Typography>
            </CardContent>

            <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "0" }}>
              <div
                style={{
                  height: 1,
                  width: "230px",
                  backgroundColor: "black",
                  backgroundColor: "#BBBBBB",
                  marginTop: "35px",
                  marginLeft: "20px",
                }}
              ></div>
            </CardContent>
          </Box>
          <CardActions
            disableSpacing
            style={{ position: "relative", bottom: "30px" }}
          >
            <IconButton aria-label="remove">
              <DeleteIcon
                onClick={() => onChangePhotos("DELETE", id)}
                style={{ color: VF_COLORS.secondary }}
              />
            </IconButton>
          </CardActions>
        </Card>
      );
    } else if (width <= 1199) {
      return (
        <>
          <Card
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "#F9F9F9",
              marginRight: "12px",
              marginBottom: "12px",
            }}
          >
            <CardMedia
              component="img"
              image={preview}
              style={{
                height: "100%",
                width: "100px",
                position: "relative",
                backgroundSize: "contain",
              }}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "225px",
              }}
            >
              <CardContent style={{ padding: "0" }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ marginLeft: "20px", marginTop: "30px" }}
                >
                  {name}
                </Typography>
              </CardContent>
              <CardActions disableSpacing style={{ position: "relative" }}>
                <IconButton aria-label="remove">
                  <DeleteIcon
                    onClick={() => onChangePhotos("DELETE", id)}
                    style={{ color: VF_COLORS.secondary }}
                  />
                </IconButton>
              </CardActions>
            </Box>
          </Card>
        </>
      );
    }
  };
  return changeUiByViewPortWidth();
}
