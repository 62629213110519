import Api from "../api/index";

export const createRestaurant = async (input) => {
	console.log(`our input: ${JSON.stringify(input)}`);
	let deliveries = [];
	const menuPics = input.menuPics ? await uploadMenuPics(input.menuPics) : [];
	const foodPics = input.foodPics ? await uploadFoodPics(input.foodPics) : [];
	const logo = input.logo ? await Api.filesUpload(input.logo.file) : null;

	if (input.checkDeliveroo) {
		deliveries.push({ delivery: "Deliveroo", url: input.deliverooURL, id: 1 });
	}
	if (input.checkUberEats) {
		deliveries.push({ delivery: "Uber", url: input.uberEatsURL, id: 2 });
	}
	if (input.checkJustEat) {
		deliveries.push({ delivery: "Just_Eat", url: input.justEatURL, id: 3 });
	}
	if (input.checkRestaurant) {
		deliveries.push({
			delivery: "Restaurant",
			url: input.restaurantURL,
			id: 4,
		});
	}
	console.log(input.categories);
	let daily_opening = [];
	let displayed_time = "";
	let times = [];

	let categories = await Api.getCategories();
	if (categories) {
		categories = categories.tags;
		console.log(categories);
	}
	console.log(input.categories[0]);

	let rest_categories = [];
	if (input.categories && input.categories[0]) {
		for (const category of input.categories[0]) {
			const pickedObj = categories.find((cat) => cat.category_name == category);
			const objFilter = (({ id, category_name }) => ({ id, category_name }))(pickedObj);
			rest_categories.push(objFilter);

			console.log(rest_categories);
		}
	}

	for (let opening of input.schedule) {
		console.log(opening.to_day);
		let dayString = "";
		if (opening.shifts.filter((shift) => shift.active).length > 0) {
			dayString = ` ${opening.name}: `;
		}

		for (let shift of opening.shifts) {
			//TDL switch if statement to shift.active
			if (shift.active === true) {
				console.log("this is shift from and to day:" + "from:" + opening.from_day + "to:" + opening.to_day);
				console.log(shift);
				daily_opening.push({
					from: shift.from + ":00.00",
					to: shift.to + ":00.00",
					from_day: opening.from_day,
					to_day: opening.to_day,
				});

				//TDL switch if statement to shift.active

				if (shift.from !== undefined) {
					times.push(`${shift.from}-${shift.to}`);
				}
			}
		}
		displayed_time = displayed_time + `${dayString} ${times.join(", ")}`;
	}

	let location = {...input.location, country: input.country, timezone: input.timezone };

	if(input.state && input.country === 'US') location.state = input.state; 

	let restaurant = {
		restaurant_name: input.restaurant_name,
		logo: logo,
		address: input.address,
		phone_number: input.phone_number,
		location: location,
		description: input.description,
		rest_categories: input.categories ? rest_categories : [],
		label_approved: false,
		approved: false,
		schedule: {
			displayed_time: displayed_time,
			daily_opening: daily_opening,
			always_open: input.alwaysOpen,
		},
		locale: "en",
		tags: [input.wheelchairAccessibility, input.glutenFreeOptions, input.vegan],
		labelled: {
			id: 1,
			delivery: deliveries,
			menu: {
				food_pics: foodPics,
				menu_pics: menuPics,
			},
		},
		discount: input.discountDescription.length
			? {
				description: input.discountDescription,
				terms: input.discountTerms,
			}
			: null,
		discount_list: input.discountDescription.length ? [{ description: input.discountDescription, terms: input.discountTerms, discount_type: "regular" }] : [],
		contact: {
			contact_name: input.contactName,
			role: input.role,
			email: input.email,
			phone_number: input.contactNumber,
		},
		country: input.country,
	};
	return restaurant;
};

const uploadMenuPics = async (menuPics) => {
	let newMenuPics = [];

	for (let file of menuPics) {
		let menuPic = await Api.filesUpload(file.file);
		newMenuPics.push(menuPic);
	}
	console.log("amount of menu pics " + newMenuPics.length);
	return newMenuPics;
};

const uploadFoodPics = async (foodPics) => {
	let newFoodPics = [];

	for (let file of foodPics) {
		let foodPic = await Api.filesUpload(file.file);
		newFoodPics.push(foodPic);
	}
	console.log("amount of menu pics " + newFoodPics.length);
	return newFoodPics;
};
