import React, { useState, useEffect } from "react";
import Shift from "./Shift";
import { uuid } from "uuidv4";
import { Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function DailyOpening(props) {
	const useStyles = makeStyles((theme) => ({
		root: {
			padding: theme.spacing(3, 2),
		},
		container: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 400,
		},
		closed: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			color: "grey",
		},

		shiftsContainer: {
			display: "flex",
			width: "100%",
		},
		hideShiftsContainer: {
			display: "none",
		},
		day: {
			display: "flex",
			flexDirection: "column",
		},
		daySwitch: {
			display: "flex",
			flexDirection: "column",
			marginLeft: "50px",
			justifyContent: "flex-end",
		},
		addBtn: {
			border: `1px solid #26626833`,
			borderRadius: "50%",
			width: "44px",
			height: "44px",
			marginLeft: "20px",
			marginRight: "100px",
		},
		mobileAddBtn: {
			marginTop: "5px",
			marginBottom: "10px",
			border: `1px solid #26626833`,
			borderRadius: "50%",
			width: "44px",
			height: "44px",
		},
		gradient: {
			right: 0,
			width: "30%",
			height: "70px",
			background: "rgb(0,0,0)",
			background: "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 56%)",
			position: "absolute",
			zIndex: 50,
			pointerEvents: "none",
		},
	}));
	const { dayName, shifts } = props;
	const [isActive, setIsActive] = useState(true);

	const classes = useStyles();

	useEffect(() => {
		if (shifts.length > 0 && shifts[0].active === true) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [shifts]);

	const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
		return scrollHeight > clientHeight || scrollWidth > clientWidth;
	};

	const openFirstShift = () => {
		if (!shifts.length) {
			props.onScheduleChange("ADD", { shift: { id: uuid(), active: true } });
			setIsActive(true);
		}
	};

	const changeSwitchWhenNoShifts = () => {
		setIsActive(false);
	};

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
		} else if (width <= 1199) {
		}
	};

	return (
		<div
			className="dayShift"
			style={
				width > 1199
					? {
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
					  }
					: { display: "flex", flexDirection: "column", alignItems: "center" }
			}>
			{width > 1199 ? <div className={classes.gradient}></div> : null}

			{dayName !== "Monday" ? <div style={{ height: "1px", width: "100%", backgroundColor: "#333333" }}></div> : null}

			<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
				<div className={classes.day}>
					<p
						style={{
							fontWeight: "600",
							fontSize: "16px",
							minWidth: "90px",
							color: "#333333",
						}}>
						{dayName}
					</p>
				</div>
				<div className={classes.daySwitch}>
					<FormControlLabel
						control={
							<Switch
								color="secondary"
								name="open"
								checked={shifts.length && isActive}
								onChange={() => {
									if (!shifts.length && !isActive) {
										console.log("here at switch toggle");
										openFirstShift();
										setIsActive(true);
									}
									if (shifts.length && isActive) {
										setIsActive(false);
										shifts[0].active = false;
										if (!shifts[0].hasOwnProperty("from") && !shifts[0].hasOwnProperty("to")) {
											props.onScheduleChange("DELETE", { id: shifts[0].id });
											console.log("shift deleted casue empty");
										}
										console.log("clicked");
										console.log(shifts);
									}
									if (shifts.length && !isActive) {
										setIsActive(true);
										shifts[0].active = true;
										console.log(shifts);
									}
								}}
							/>
						}
						label={shifts.length && isActive ? "Open" : "Closed"}
					/>
				</div>
			</div>
			<div className={isActive ? classes.shiftsContainer : classes.hideShiftsContainer} style={width <= 1199 ? { flexDirection: "column" } : null}>
				{shifts.map((shift, index) => {
					return (
						<Shift
							onShiftDelete={() => {
								console.log("we are deleting this shift: " + JSON.stringify(shift));
								console.log(JSON.stringify(shifts.length));
								if (shifts.length === 1) {
									shifts[0].active = false;
									console.log("active state is false now");
									console.log(shifts[0]);
								}
								props.onScheduleChange("DELETE", { id: shift.id });
							}}
							onShiftChange={(shift) => {
								console.log("trying to update this shift: ", shift);
								// JSON.stringify({

								//   ID: shift.id,
								//   FROM: shift.from,
								//   TO: `${shift.to ? shift.to : null}`,
								//   DAY: dayName,
								//   ACTIVE: shift.active,
								// })

								props.onScheduleChange("UPDATE", { shift });
							}}
							key={index}
							day={shift.from_day}
							from={shift.from}
							to={shift.to}
							id={shift.id}
							changeSwitch={changeSwitchWhenNoShifts}
							shiftExist={shifts.length}
							active={shift.active}
						/>
					);
				})}
			</div>
			<IconButton className={width > 1199 ? classes.addBtn : classes.mobileAddBtn} style={!shifts.length || !isActive ? { display: "none" } : null} onClick={() => props.onScheduleChange("ADD", { shift: { id: uuid() } })}>
				<AddIcon color="secondary" style={{ width: "30px", height: "30px" }} />
			</IconButton>
		</div>
	);
}
