import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import Deliveroo from "../assets/Deliveroo.png";
import justEat from "../assets/justEat.png";
import uberEats from "../assets/uberEats.png";
import restIcon from "../assets/restIcon.png";
import RestaurantOtherFeatures from "./RestaurantOtherFeatures";
import AccessibleIcon from "@material-ui/icons/Accessible";
import Gluten from "../assets/gluten.svg";
import Api from "./../api/index";
import _without from "lodash/without";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

import "./restaurantFeatures.css";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function RestaurantFeatures(props) {
	const useStyles = makeStyles((theme) => ({
		container: {
			backgroundColor: "white",
			width: "50%",
			position: "relative",
			zIndex: "5",
			display: "flex",
			flexDirection: "column",
			borderBottomLeftRadius: "30px",
			paddingLeft: "5%",
			paddingTop: "5%",
			pointerEvents: "auto",
		},
		categories: {
			width: "90%",
			display: "flex",
			flexDirection: "column",
		},
		mobileViewCategories: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
		},
		offer: {
			display: "flex",
			flexDirection: "column",
			width: "90%",
			paddingTop: "5%",
			paddingBottom: "7%",
		},
		otherFeature: {
			display: "flex",
			paddingBottom: "5%",
		},
		icon: {
			position: "relative",
			color: "#85B858",
			height: "40px",
			width: "40px",
			marginRight: "20px",
			marginLeft: "10px",
			marginBottom: "20px",
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
			width: "100%",
		},
		chips: {
			display: "flex",
			flexWrap: "wrap",
		},
		chip: {
			margin: 2,
			backgroundColor: "#C8678D",
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
		selectRoot: {
			"&:focus": {
				backgroundColor: "white",
			},
		},
		menuPaper: {
			maxHeight: "200px",
			[theme.breakpoints.down("sm")]: {
				maxWidth: "50px",
			},
			[theme.breakpoints.up("md")]: {
				maxWidth: "100px",
			},
			[theme.breakpoints.up("lg")]: {
				maxWidth: "150px",
			},
		},
		categoryAlert: {
			color: "red",
		},
	}));
	const classes = useStyles();
	const [selectedCategories, setSelectedCategories] = useState(props.formInput.categories[0] || []);

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();
	const { categories, setCategories, tags, setTags } = props;

	const otherFeatures = [
		{
			logo: restIcon,
			value: "checkRestaurant",
			url: "restaurantURL",
			urlError: "restaurantURLError",
			name: "Restaurant delivery",
		},
		{
			logo: Deliveroo,
			value: "checkDeliveroo",
			url: "deliverooURL",
			urlError: "deliverooURLError",
			name: "Deliveroo",
		},
		{
			logo: justEat,
			value: "checkJustEat",
			url: "justEatURL",
			urlError: "justEatURLError",
			name: "Just Eat",
		},
		{
			logo: uberEats,
			value: "checkUberEats",
			url: "uberEatsURL",
			urlError: "uberEatsURLError",
			name: "Uber Eats",
		},
	];

	useEffect(() => {
		const debounceTimer = setTimeout(async () => {
			const categories = await Api.getCategories();
			if (categories) {
				console.log("success!");
				setCategories(categories.tags);
				console.log(categories.tags);
			}
		}, 500);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, []);

	const handleChange = (evt) => {
		setSelectedCategories(evt.target.value);
		console.log(evt.target);
	};
	useEffect(() => {
		const debounceTimer = setTimeout(async () => {
			const ApiTags = await Api.getTags();
			console.log("awaiting for tags");
			if (ApiTags) {
				console.log("success!");
				await setTags(ApiTags);
				console.log(ApiTags);
				console.log(tags);
			}
		}, 500);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, []);

	let newCategories = {};

	const handleDelete = (e, value) => {
		e.preventDefault();
		console.log(value);
		setSelectedCategories((current) => _without(current, value));
		newCategories = [
			...props.formInput.categories.map((category) => {
				console.log(value);
				console.log(category);
				return category.filter((c) => c !== `${value}`);
			}),
		];
		console.log(newCategories);
		props.handleInput("categories", newCategories);
	};

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div className={classes.container}>
					<div className={classes.categories}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
							}}>
							Categories
						</p>
						<p
							style={{
								margin: 0,
								color: "#AAAAAA",
								fontSize: "12px",
								maxWidth: "500px",
							}}>
							Choose all relevant categories, this will help customers find your business!
						</p>

						<div className={classes.redBackground}>
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-mutiple-chip-label" className={props.alert.alertErr.categories ? classes.categoryAlert : null}>
									Choose a category
								</InputLabel>
								<Select
									MenuProps={{
										classes: { paper: classes.menuPaper },
										getContentAnchorEl: () => null,
									}}
									classes={{ root: classes.selectRoot }}
									labelId="demo-mutiple-chip-checkbox-label"
									id="demo-mutiple-chip-checkbox"
									multiple
									value={selectedCategories}
									onChange={(evt) => {
										console.log(evt);
										handleChange(evt);

										props.handleInput("categories", [evt.target.value]);
									}}
									renderValue={(selectedCategories) => (
										<div className={classes.chips}>
											{selectedCategories.map((category) => (
												<Chip key={category} label={category} deleteIcon={<CancelIcon style={{ color: "rgba(0, 0, 0, 0.35)" }} onMouseDown={(event) => event.stopPropagation()} />} className={classes.chip} onDelete={(e) => handleDelete(e, category)} onClick={() => console.log("clicked chip")} />
											))}
										</div>
									)}>
									{categories &&
										categories.map((category) => (
											<MenuItem key={category.id} value={category.category_name} id={category.id}>
												<Checkbox checked={selectedCategories && selectedCategories.includes(category.category_name)} />
												<ListItemText primary={category.category_name} />
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div>
					</div>
					<div className={classes.offer}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
							}}>
							Offer a benefit?
						</p>
						<TextField
							multiline="true"
							label="What's your benefit"
							name="benefitDescription"
							value={props.formInput.discountDescription}
							className={classes.discountField}
							onChange={(evt) => props.handleInput("discountDescription", evt.target.value)}
							placeholder="Describe the benefit you offer to Vegan-Friendly's members"
							variant="standard"
						/>
						<TextField
							multiline="true"
							id="standard-basic"
							label="Your benefit terms"
							name="benefitTerms"
							value={props.formInput.discountTerms}
							className={classes.discountField}
							onChange={(evt) => props.handleInput("discountTerms", evt.target.value)}
							placeholder="Describe the terms and conditions of your benefit"
							variant="standard"
						/>
					</div>
					<p
						style={{
							margin: "0",
							fontSize: "24px",
							color: "#707070",
							letterSpacing: "0px",
							paddingBottom: "3%",
							fontWeight: "600",
						}}>
						Other Features
					</p>
					{otherFeatures.map((delivery, index) => (
						<RestaurantOtherFeatures
							delivery={delivery}
							key={index}
							handleChange={props.handleChange}
							handleInput={props.handleInput}
							formInput={props.formInput}
							checked={props.formInput[delivery.value]}
							onURLChange={(url) => props.handleInput(delivery.url, url)}
							url={props.formInput[delivery.url]}
							errorMsg={props.formInput[delivery.urlError]}
						/>
					))}
					<div className={classes.otherFeature}>
						<div className={classes.glutenIcon}>
							<img
								src={Gluten}
								style={{
									position: "relative",
									height: "40px",
									width: "40px",
									marginRight: "20px",
									marginLeft: "10px",
								}}
							/>
						</div>

						<FormControlLabel
							control={
								<Switch
									color="secondary"
									name="gluten"
									onChange={() => {
										props.formInput.glutenFreeOptions === false ? props.handleInput("glutenFreeOptions", tags[2]) : props.handleInput("glutenFreeOptions", false);
									}}
									checked={props.formInput.glutenFreeOptions !== false ? true : false}
								/>
							}
							label="Gluten Free "
						/>
					</div>
					<div className={classes.otherFeature}>
						<AccessibleIcon
							style={{
								position: "relative",
								color: "#85B858",
								height: "40px",
								width: "40px",
								marginRight: "20px",
								marginLeft: "10px",
								marginBottom: "20px",
							}}
						/>
						<FormControlLabel
							control={
								<Switch
									color="secondary"
									name="wheelchair"
									onChange={() => {
										props.formInput.wheelchairAccessibility === false ? props.handleInput("wheelchairAccessibility", tags[1]) : props.handleInput("wheelchairAccessibility", false);
									}}
									checked={props.formInput.wheelchairAccessibility !== false ? true : false}
								/>
							}
							label="Wheelchair Accessibility"
						/>
					</div>
					<p
						style={{
							margin: 0,
							color: "rgb(170, 170, 170)",
							fontSize: "12px",
							width: "90%",
						}}>
						*We're currently collecting data for our Accessibility feature,
					</p>
					<p
						style={{
							margin: 0,
							color: "rgb(170, 170, 170)",
							fontSize: "12px",
							width: "90%",
						}}>
						which should be available on the app in the future.
					</p>
				</div>
			);
		} else if (width <= 1199) {
			return (
				<div className={classes.container} style={{ paddingLeft: 0 }}>
					<div className={classes.categories} style={{ alignItems: "center", width: "100%", marginTop: "50px" }}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
							}}>
							Categories
						</p>
						<p
							style={{
								margin: 0,
								color: "#AAAAAA",
								fontSize: "12px",
								maxWidth: "500px",
								textAlign: "center",
							}}>
							Choose all relevant categories, this will help customers find your business!
						</p>
						<div style={{ width: "100%" }}>
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-mutiple-chip-label" className={props.alert.alertErr.categories ? classes.categoryAlert : null}>
									Choose a category
								</InputLabel>

								<Select
									MenuProps={{
										classes: { paper: classes.menuPaper },
										getContentAnchorEl: () => null,
									}}
									classes={{ root: classes.selectRoot }}
									labelId="demo-mutiple-chip-checkbox-label"
									id="demo-mutiple-chip-checkbox"
									multiple
									value={selectedCategories}
									helperText="this is helper text to help the text"
									onChange={(evt) => {
										console.log(evt);
										handleChange(evt);

										props.handleInput("categories", [evt.target.value]);
									}}
									onOpen={() => console.log("select opened")}
									renderValue={(selectedCategories) => (
										<div className={classes.chips}>
											{selectedCategories.map((category) => (
												<Chip key={category} label={category} deleteIcon={<CancelIcon style={{ color: "rgba(0, 0, 0, 0.35)" }} onMouseDown={(event) => event.stopPropagation()} />} className={classes.chip} onDelete={(e) => handleDelete(e, category)} onClick={() => console.log("clicked chip")} />
											))}
										</div>
									)}>
									{categories &&
										categories.map((category) => (
											<MenuItem key={category.id} value={category.category_name} id={category.id}>
												<Checkbox checked={selectedCategories.includes(category.category_name)} />
												<ListItemText primary={category.category_name} />
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div>
					</div>
					<div className={classes.offer} style={{ alignItems: "center", width: "100%", marginTop: "50px" }}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
							}}>
							Offer a benefit?
						</p>
						<TextField
							multiline="true"
							label="What's your benefit"
							name="benefitDescription"
							value={props.formInput.discountDescription}
							className={classes.discountField}
							onChange={(evt) => props.handleInput("discountDescription", evt.target.value)}
							placeholder="Describe the benefit you offer to Vegan-Friendly's members"
							variant="standard"
							fullWidth
						/>
						<TextField
							multiline="true"
							id="standard-basic"
							label="Your benefit terms"
							name="benefitTerms"
							value={props.formInput.discountTerms}
							className={classes.discountField}
							onChange={(evt) => props.handleInput("discountTerms", evt.target.value)}
							placeholder="Describe the terms and conditions of your benefit"
							variant="standard"
							fullWidth
						/>
					</div>
					<div
						style={{
							marginTop: "50px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								paddingBottom: "50px",
								fontWeight: "600",
								textAlign: "center",
							}}>
							Other Features
						</p>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								width: "100%",
							}}>
							{otherFeatures.map((delivery, index) => (
								<RestaurantOtherFeatures
									delivery={delivery}
									key={index}
									handleChange={props.handleChange}
									handleInput={props.handleInput}
									formInput={props.formInput}
									checked={props.formInput[delivery.value]}
									onURLChange={(url) => props.handleInput(delivery.url, url)}
									url={props.formInput[delivery.url]}
								/>
							))}
							<div
								className={classes.glutenIcon}
								style={{
									display: "flex",
									width: "100%",
									paddingBottom: "5%",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<div className={classes.avatar} style={{ marginRight: "20px", marginLeft: "10px" }}>
									<Avatar src={Gluten}></Avatar>
								</div>

								<FormControlLabel
									control={
										<Switch
											color="secondary"
											name="gluten"
											onChange={() => {
												props.formInput.glutenFreeOptions === false ? props.handleInput("glutenFreeOptions", tags[2]) : props.handleInput("glutenFreeOptions", false);
											}}
											checked={props.formInput.glutenFreeOptions !== false ? true : false}
										/>
									}
									label="Gluten Free"
								/>
							</div>

							<div
								className={classes.otherFeature}
								style={{
									padding: 0,
									width: "100%",
									paddingBottom: "5%",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<AccessibleIcon
									style={{
										position: "relative",
										color: "#85B858",
										height: "40px",
										width: "40px",
										marginRight: "20px",
										marginLeft: "10px",
									}}
								/>
								<FormControlLabel
									control={
										<Switch
											color="secondary"
											name="wheelchair"
											onChange={() => {
												props.formInput.wheelchairAccessibility === false ? props.handleInput("wheelchairAccessibility", tags[1]) : props.handleInput("wheelchairAccessibility", false);
											}}
											checked={props.formInput.wheelchairAccessibility !== false ? true : false}
										/>
									}
									label="Wheelchair Accessibility"
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	return changeUiByViewPortWidth();
}
