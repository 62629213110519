import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

import { VF_COLORS } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%%",
    maxHeight: "200px",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    position: "relative",
    bottom: "30px",
    zIndex: "1",
    boxShadow: "5px 19px 15px 4px rgba(0,0,0,0.33)",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "& > .li-item": {
      marginTop: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    "&::-webkit-scrollbar-thumb": {
      background: " #888",
      borderRadius: "50px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    // "& > div:first-child": {
    //   marginTop: "30px !important",
    // },
  },
  rootHidden: {
    width: "100%%",
    maxHeight: "200px",
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    position: "relative",
    bottom: "30px",
    zIndex: "1",
    boxShadow: "5px 19px 15px 4px rgba(0,0,0,0.33)",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "& > .li-item": {
      marginTop: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    "&::-webkit-scrollbar-thumb": {
      background: " #888",
      borderRadius: "50px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },

  itemOn: {
    background: VF_COLORS.primary,
  },
  itemOff: {},
}));

export default function PlacesList(props) {
  const classes = useStyles();
  const [selectedPlaceId, setSelectedPlaceId] = React.useState("");
  const list = props.places;

  return (
    <List className={props.loader ? classes.rootHidden : classes.root}>
      {props.loader ? (
        <div
          style={{
            position: "absolute",
            display: "block",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress size={50} />
          </div>
        </div>
      ) : null}

      {list &&
        list.length > 0 &&
        list.map((place, index) => {
          const labelId = `checkbox-list-label-${place.place_id}`;

          return (
            <ListItem
              style={
                index === 0
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "30px",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }
              }
              key={place.place_id}
              className={
                place.place_id === selectedPlaceId
                  ? classes.itemOn + " li-item"
                  : classes.itemOff + " li-item"
              }
              role={undefined}
              dense
              button
              onClick={() => {
                setSelectedPlaceId(place.place_id);
                props.setRestaurantId(place.place_id);
                props.setFormIsTouched(false);
              }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                id={labelId}
                disableTypography
                primary={
                  <Typography
                    type="body1"
                    style={{ color: "#333333", fontSize: 16 }}
                  >
                    {place.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    type="body1"
                    style={{ color: "#666666", fontSize: 13 }}
                  >
                    {place.formatted_address}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
    </List>
  );
}
