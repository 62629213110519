import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Switch, FormControlLabel } from "@material-ui/core";
import RestaurantInformation from "./RestaurantInformation";
import RestaurantBusinessHours from "./RestaurantBusinessHours";
import RestaurantFeatures from "./RestaurantFeatures";
import Schedule from "./Schedule/Schedule";
import * as config from "../config";
import Api from "../api/index";
import { uuid } from "uuidv4";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import "./RestaurantDetails.css";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function RestaurantDetails(props) {
	const [gettingCertified, setGettingCertified] = React.useState(false);
	const [tags, setTags] = useState([]);
	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	// const [restaurantName, setRestaurantName] = useState("");
	// const [address, setAddress] = useState("");
	// const [restaurantPhone, setRestaurantPhone] = useState("");

	const [categories, setCategories] = React.useState((config.test || config.testStep) === 1 ? config.exampleCategories : []);
	const [loader, setLoader] = useState(false);
	const handleChange = (evt) => {
		const name = evt.target.name;
		const value = evt.target.checked;
		console.log("our value: " + name, value);
		props.handleInput(name, value);
	};

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	useEffect(() => {
		const debounceTimer = setTimeout(async () => {
			const ApiTags = await Api.getTags();
			console.log("awaiting for tags");
			if (ApiTags) {
				console.log("success!");
				setTags(ApiTags);
				console.log(ApiTags);
				console.log(tags);
			}
		}, 500);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, []);

	React.useEffect(() => {
		(async () => {
			console.log(props.formIsTouched);
			if (props.formIsTouched) {
				setLoader(false);
				setDataIsLoaded(false);
			} else if (!config.test) {
				console.log("here");
				props.setFormIsTouched(true);
				setLoader(true);
				console.log("our restaurantId: " + props.restaurantId);
				if (!config.test || config.testStep !== 1) {
					const response = await Api.getPlaceById(props.restaurantId);
					if (response) {
						console.log("at response");
						console.log(response.categories);
						setCategories(response.categories);
						response && response.place && console.log("got this response place: " + JSON.stringify(response.place));
						if (response.place !== null) {
							fillInForm(response.place);
							setDataIsLoaded(true);
						}
						setLoader(false);
					}
				}
			}
		})();
		return () => {
			console.log("unmounting!");
		};
	}, []);

	function getWorkingHours(daily_opening) {
		const { weekDays } = config;

		let workingHours = [];

		for (let weekDay of weekDays) {
			console.log("getting working hours!");
			let shifts = daily_opening ? daily_opening.filter((opening) => opening.from_day === weekDay.from_day) : [{ id: uuid() }];
			shifts.length !== 0 ? Object.assign(shifts[0], { active: true }) : shifts.push({ active: false, id: uuid() });
			workingHours.push({
				from_day: weekDay.from_day,
				to_day: weekDay.to_day,
				name: weekDay.name,
				shifts: [...shifts],
			});
		}
		console.log("returning these working hours: " + JSON.stringify(workingHours));
		return workingHours;
	}

	async function fillInForm(place) {
		if (place) {
			if (place["name"]) {
				props.handleInput("restaurant_name", place["name"]);
				// setRestaurantName(place["name"]);
			}
			if (place["address"]) {
				props.handleInput("address", place["address"]);
				// setAddress(place["address"]);
			}
			if (place["phone_number"]) {
				props.handleInput("phone_number", place["phone_number"]);
				// setRestaurantPhone(place["phone_number"]);
			}

			if(place["location"]) {
				props.handleInput("location", place["location"]);				
			}
		}

		place.schedule.daily_opening.map((opening) => {
			opening.from = opening.from.split(":").slice(0, 2).join(":");
			opening.to = opening.to.split(":").slice(0, 2).join(":");
		});

		const schedule = [...getWorkingHours(place && place.schedule ? place.schedule.daily_opening : [])];
		props.handleInput("schedule", schedule);
	}

	const useStyles = makeStyles((theme) => ({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "90vw",
			padding: "0",
			height: "100%",
		},

		detail: {
			display: "flex",
			margin: "0",
			padding: "0",
			width: "90vw",
			height: "100%",
		},
		openTime: {
			backgroundColor: "white",
			overflow: "hidden",
			width: "50%",
			position: "relative",
			zIndex: "5",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			height: "100%",
			borderBottomRightRadius: "30px",
			paddingTop: "5%",
			paddingBottom: "5%",
		},
	}));
	const classes = useStyles();
	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div className={classes.container}>
					{loader ? (
						<div
							style={{
								position: "fixed",
								display: "block",
								width: "100%",
								height: "100%",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 10,
							}}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									height: "100%",
								}}>
								<CircularProgress size={200} />
							</div>
						</div>
					) : null}

					<RestaurantInformation
						handleInput={props.handleInput}
						handleChange={props.handleChange}
						formInput={props.formInput}
						alert={props.alert}
						tags={tags}
						setTags={setTags}
						dataIsLoaded={dataIsLoaded}
						// restaurantName={restaurantName}
						// setRestaurantName={setRestaurantName}
						// address={address}
						// setAddress={setAddress}
						// restaurantPhone={restaurantPhone}
						// setRestaurantPhone={setRestaurantPhone}
					/>
					<div className={classes.detail}>
						<RestaurantFeatures handleInput={props.handleInput} handleChange={handleChange} formInput={props.formInput} alert={props.alert} categories={categories} setCategories={setCategories} tags={tags} setTags={setTags} />
						<div className={classes.openTime}>
							<div className={classes.scheduleContainer}>
								<Schedule schedule={props.formInput.schedule} alwaysOpen={props.formInput.alwaysOpen} handleInput={props.handleInput} alert={props.alert} setAlert={props.setAlert} loader={loader} />
							</div>
						</div>
					</div>
				</div>
			);
		} else if (width <= 1199) {
			return (
				<div className={classes.container}>
					{loader ? (
						<div
							style={{
								position: "fixed",
								display: "block",
								width: "100%",
								height: "100%",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 10,
							}}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									height: "100%",
								}}>
								<CircularProgress size={200} />
							</div>
						</div>
					) : null}

					<RestaurantInformation handleInput={props.handleInput} handleChange={props.handleChange} formInput={props.formInput} alert={props.alert} tags={tags} setTags={setTags} dataIsLoaded={dataIsLoaded} />
					<div
						className={classes.detail}
						style={{
							flexDirection: "column",
							alignItems: "center",
							backgroundColor: "white",
							position: "relative",
						}}>
						<RestaurantFeatures handleInput={props.handleInput} handleChange={handleChange} formInput={props.formInput} alert={props.alert} categories={categories} setCategories={setCategories} tags={tags} setTags={setTags} />
						<div className={classes.scheduleContainer}>
							<Schedule schedule={props.formInput.schedule} alwaysOpen={props.formInput.alwaysOpen} handleInput={props.handleInput} alert={props.alert} loader={loader} />
						</div>
					</div>
				</div>
			);
		}
	};
	return changeUiByViewPortWidth();
}
