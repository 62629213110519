import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { test } from './config';

//if (test) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
//}

/* else {
  const target = document.getElementById('erw-root');
  if (target) { ReactDOM.render(<App />, target); }
} */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWApagep
