import { makeStyles } from "@material-ui/core/styles";
import { VF_COLORS } from "../config";
const useStyles = makeStyles(theme => ({

  categoriesContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'center',
    margin: theme.spacing(1)
  },

  title: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: theme.spacing(1)
  },

  deliveryImage: {
    height: 50,
    width: 50
  },

  root: {
    display: "flex",
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    '& .MuiInput-underline:after': {
      borderBottomColor: VF_COLORS.primary,
    },

    marginRight: 100,
    marginLeft: 100,


  },
  container: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

  },

  textField: {
    margin: theme.spacing(2),
    flex: 1,
    '&:hover': {
      borderColor: VF_COLORS.primary,
    },
  },

  description: {

    width: '100%'
  },
  discountField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50%'
  },

  scheduleContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,

  },

  labelledContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },


  weekDaysContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'auto'
  },

  increaseExposureText: {
    fontWeight: 'bold'
  }
}
))

export default useStyles;