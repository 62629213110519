import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { weekDays } from "../config";
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Deliveroo from "../assets/Deliveroo.png";
import restIcon from "../assets/restIcon.png";
import justEat from "../assets/justEat.png";
import uberEats from "../assets/uberEats.png";
import Avatar from "@material-ui/core/Avatar";
import Gluten from "../assets/gluten.svg";
import AccessibleIcon from "@material-ui/icons/Accessible";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		width: "100%",
		height: "100%",
	},
	leftContainer: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
		height: "100%",
		paddingLeft: "5%",
	},
	rightContainer: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
		height: "100%",
	},
	restaurantInformationContainer: {
		display: "flex",
		flexDirection: "column",
	},
	textField: {
		width: "90%",
	},
	benefitAndTagsContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "start",
	},
	shfitsAndChipsContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
	deliveryAndAccessContainer: {
		display: "flex",
		flexDirection: "column",
	},
	benefitContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},
	tagsContainer: {
		display: "flex",
		flexWrap: "wrap",
		width: "30%",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	contactContainer: {
		display: "flex",
		flexDirection: "column",
	},
	openingTimesContainer: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
	},
	deliverAvatarContainer: {
		display: "flex",
		justifyContent: "start",
	},
	accessibilityIconsContainer: {
		display: "flex",
		paddingBottom: "50px",
	},
	categories: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
	},
	dayShiftsContainer: {
		display: "flex",
		flexDirection: "row",
		whiteSpace: "nowrap",
		overflow: "auto",
		"&::-webkit-scrollbar": {
			width: "1px",
			height: "5px",
		},
		"& > .li-item": {
			marginTop: "10px",
		},
		"&::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"&::-webkit-scrollbar-thumb": {
			background: " #888",
			borderRadius: "50px",
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "#555",
		},
	},
	restaurantInformation: {
		color: "#707070",
		fontSize: "24px",
	},
	openingTimes: {
		color: "#707070",
		fontSize: "24px",
	},
	deliveryOptions: {
		color: "#707070",
		fontSize: "24px",
	},
	accessibilityOptions: {
		color: "#707070",
		fontSize: "24px",
	},
	benefit: {
		color: "#707070",
		fontSize: "24px",
	},
	category: {
		color: "#707070",
		fontSize: "24px",
	},
	contact: {
		color: "#707070",
		fontSize: "24px",
	},
	spanTags: {
		color: "#333333",
		fontSize: "14px",
	},
	pTags: {
		color: "#333333",
		fontSize: "16px",
	},
	chip: {
		backgroundColor: "#C8678D",
		marginBottom: "10px",
	},
}));

export default function Summary(props) {
	const classes = useStyles();
	const { restaurant, setActiveStep, formInput } = props;

	console.log(restaurant.tags);

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const otherFeatures = {
		Restaurant: {
			logo: restIcon,
		},
		Deliveroo: {
			logo: Deliveroo,
		},
		Just_Eat: {
			logo: justEat,
		},
		Uber: {
			logo: uberEats,
		},
	};

	const getDayShifts = weekDays.map((weekDay) => {
		let shift = restaurant.schedule.daily_opening.filter((el) => el.from_day == weekDay.from_day);
		console.log(shift);
		console.log(shift[0]);

		const changeUiByViewPortWidth = () => {
			if (width > 1199) {
				return (
					<div className={classes.daily_opening}>
						{shift.length ? <p style={{ fontWeight: "bold" }}>{weekDay.name}</p> : null}
						<div className={classes.dayShiftsContainer}>
							{shift.map((s) => {
								let fromConverted = s.from.split(":").splice(0, 2).join(":");
								let toConverted = s.to.split(":").splice(0, 2).join(":");
								return (
									<p style={{ marginRight: "20px" }}>
										{fromConverted} - {toConverted}
									</p>
								);
							})}
						</div>
					</div>
				);
			} else if (width <= 1199) {
				return (
					<div
						className={classes.daily_opening}
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						{shift.length ? <p style={{ fontWeight: "bold" }}>{weekDay.name}</p> : null}
						<div style={{ display: "flex", flexDirection: "column" }}>
							{shift.map((s) => {
								let fromConverted = s.from.split(":").splice(0, 2).join(":");
								let toConverted = s.to.split(":").splice(0, 2).join(":");

								return (
									<p>
										{fromConverted} - {toConverted}
									</p>
								);
							})}
						</div>
					</div>
				);
			}
		};
		return changeUiByViewPortWidth();
	});

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						position: "relative",
						width: "90vw",
						padding: "0",
						height: "100%",
						backgroundColor: "white",
						borderRadius: "30px",
						justifyContent: "center",
						zIndex: 3,
					}}>
					<div className={classes.container}>
						<div className={classes.leftContainer}>
							<div className={classes.restaurantInformationContainer}>
								<p className={classes.restaurantInformation}>Restaurant Information</p>

								<TextField
									className={classes.textField}
									label="Restaurant name"
									type="text"
									defaultValue={restaurant.restaurant_name}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>

								<TextField
									className={classes.textField}
									label="Adress"
									type="text"
									defaultValue={restaurant.address}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>

								<TextField
									className={classes.textField}
									label="Phone number"
									type="text"
									defaultValue={restaurant.phone_number}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>

								{restaurant.description ? (
									<TextField
										className={classes.textField}
										label="Description"
										multiline="true"
										type="text"
										defaultValue={restaurant.description}
										variant="standard"
										inputProps={{ readOnly: true, disabled: true }}
										// InputLabelProps={{
										//   style: { color: "#c8678d" },
										// }}
									/>
								) : (
									<TextField
										className={classes.textField}
										label="Description"
										multiline="true"
										type="text"
										defaultValue="You have not entered restuarant description"
										variant="standard"
										inputProps={{ readOnly: true, disabled: true }}
										// InputLabelProps={{
										//   style: { color: "#c8678d" },
										// }}
									/>
								)}
							</div>
							<div className={classes.benefitContainer}>
								{restaurant.discount && restaurant.discount.description ? (
									<div style={{ width: "100%" }}>
										<p className={classes.benefit}>Benefits</p>
										<TextField
											style={{ width: "90%" }}
											multiline="true"
											label="Your benefit"
											type="text"
											defaultValue={restaurant.discount.description}
											variant="standard"
											inputProps={{ readOnly: true, disabled: true }}
											// InputLabelProps={{
											//   style: { color: "#c8678d" },
											// }}
										/>

										{restaurant.discount.description ? (
											<>
												<p className={classes.pTags}>your benefit terms</p>
												<TextField
													style={{ width: "90%" }}
													multiline="true"
													label="Your benefit terms"
													type="text"
													defaultValue={restaurant.discount.terms}
													variant="standard"
													inputProps={{ readOnly: true, disabled: true }}
													// InputLabelProps={{
													//   style: { color: "#c8678d" },
													// }}
												/>
											</>
										) : null}
									</div>
								) : (
									<div>
										<p className={classes.benefit}>Benefits</p>
										<span className={classes.spanTags}>You are not offering any benefits</span>
									</div>
								)}
							</div>
							<div className={classes.deliveryAndAccessContainer}>
								<p className={classes.deliveryOptions}>Delivery Options</p>
								{restaurant.labelled && restaurant.labelled.delivery && restaurant.labelled.delivery.length > 0 ? (
									<div className={classes.deliverAvatarContainer}>
										{restaurant.labelled.delivery.map((deliver) => {
											console.log(JSON.stringify(deliver.delivery));
											console.log(otherFeatures[deliver.delivery]);
											return (
												<Avatar
													style={{
														width: "50px",
														height: "50px",
														marginLeft: "20px",
													}}
													src={otherFeatures[deliver.delivery].logo}
													id={otherFeatures[deliver.delivery]}></Avatar>
											);
										})}
									</div>
								) : (
									<p className={classes.spanTags}>Delivery options not available</p>
								)}

								<p className={classes.accessibilityOptions}>Accessibility Options</p>
								{!restaurant.tags.some((x) => x) ? (
									<p className={classes.spanTags}>Accessibility options not available</p>
								) : (
									<div className={classes.accessibilityIconsContainer}>
										{restaurant.tags[0] !== false ? (
											<AccessibleIcon
												style={{
													position: "relative",
													color: "#85B858",
													height: "50px",
													width: "50px",
													marginLeft: "20px",
												}}
											/>
										) : null}
										{restaurant.tags[1] !== false ? (
											<img
												src={Gluten}
												style={{
													position: "relative",
													height: "50px",
													width: "50px",
													marginLeft: "20px",
												}}
											/>
										) : null}
										{restaurant.tags[2] !== false ? (
											<div
												style={{
													borderRadius: "50%",
													backgroundColor: "white",
													border: "3px solid #85B858",
													position: "relative",
													height: "50px",
													width: "50px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginLeft: "20px",
												}}>
												<p style={{ textAlign: "center", fontSize: "10px", fontWeight: "bold", color: "#85B858" }}>100% Vegan</p>
											</div>
										) : null}
									</div>
								)}
							</div>
						</div>
						<div className={classes.rightContainer}>
							{restaurant.contact &&  <div className={classes.contactContainer}>
							<p className={classes.contact}>Contact</p>
								<TextField
									className={classes.textField}
									label="Name Surname"
									type="text"
									defaultValue={restaurant.contact.contact_name}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>
								<TextField
									className={classes.textField}
									label="role"
									type="text"
									defaultValue={restaurant.contact.role}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>
								<TextField
									className={classes.textField}
									label="Email"
									type="text"
									defaultValue={restaurant.contact.email}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>
								<TextField
									className={classes.textField}
									style={{ marginBottom: "40px" }}
									label="Phone number"
									type="text"
									defaultValue={restaurant.contact.phone_number}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>
							</div>}
							<div className={classes.shfitsAndChipsContainer}>
								<div className={classes.categories}>
									<p className={classes.category}>Category</p>
									<div className={classes.tagsContainer}>
										{restaurant.rest_categories && restaurant.rest_categories.map((category) => {
											return <Chip key={category.id} label={category.category_name} className={classes.chip} />;
										})}
									</div>
								</div>
								<div className={classes.openingTimesContainer}>
									<p className={classes.openingTimes}>Opening Times</p>
									{restaurant.schedule.always_open ? <p>Open 24/7 </p> : <div style={{ width: "80%" }}>{getDayShifts}</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (width <= 1199) {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						position: "relative",
						width: "90vw",
						padding: "0",
						height: "100%",
						backgroundColor: "white",
						borderRadius: "30px",
						justifyContent: "center",
						zIndex: 3,
					}}>
					<div className={classes.container} style={{ flexDirection: "column", alignItems: "center" }}>
						<div className={classes.restaurantInformationContainer} style={{ width: "90%", alignItems: "center" }}>
							<p className={classes.restaurantInformation}>Restaurant Information</p>

							<TextField
								className={classes.textField}
								style={{ width: "100%" }}
								label="Restaurant name"
								type="text"
								defaultValue={restaurant.restaurant_name}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								InputLabelProps={{
									style: { color: "#c8678d" },
								}}
							/>

							<TextField
								className={classes.textField}
								style={{ width: "100%" }}
								label="Adress"
								type="text"
								defaultValue={`${restaurant.address}${restaurant.country !== 'US' ? restaurant.country : restaurant.location.state + ', ' + restaurant.country}`}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								InputLabelProps={{
									style: { color: "#c8678d" },
								}}
							/>

							<TextField
								className={classes.textField}
								style={{ width: "100%" }}
								label="Phone number"
								type="text"
								defaultValue={restaurant.phone_number}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								InputLabelProps={{
									style: { color: "#c8678d" },
								}}
							/>

							{restaurant.description ? (
								<TextField
									className={classes.textField}
									style={{ width: "100%" }}
									label="Description"
									multiline="true"
									type="text"
									defaultValue={restaurant.description}
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>
							) : (
								<TextField
									className={classes.textField}
									style={{ width: "100%" }}
									label="Description"
									multiline="true"
									type="text"
									defaultValue="You have not entered restuarant description"
									variant="standard"
									inputProps={{ readOnly: true, disabled: true }}
									// InputLabelProps={{
									//   style: { color: "#c8678d" },
									// }}
								/>
							)}
						</div>
						<div className={classes.benefitContainer} style={{ alignItems: "center" }}>
							{restaurant.discount !== null && restaurant.discount.description ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<p className={classes.benefit}>Benefits</p>
									<TextField
										style={{ width: "90%" }}
										multiline="true"
										label="Your benefit"
										type="text"
										defaultValue={restaurant.discount.description ? restaurant.discount.description : "You are not offer any benefits"}
										variant="standard"
										inputProps={{ readOnly: true, disabled: true }}
										// InputLabelProps={{
										//   style: { color: "#c8678d" },
										// }}
									/>
									{restaurant.discount.description ? (
										<>
											<p className={classes.pTags}>your benefit terms</p>
											<TextField
												style={{ width: "90%" }}
												multiline="true"
												label="Your benefit terms"
												type="text"
												defaultValue={restaurant.discount.terms}
												variant="standard"
												inputProps={{ readOnly: true, disabled: true }}
												// InputLabelProps={{
												//   style: { color: "#c8678d" },
												// }}
											/>
										</>
									) : null}
								</div>
							) : (
								<div>
									<p className={classes.benefit} style={{ textAlign: "center" }}>
										Benefits
									</p>
									<span className={classes.spanTags}>You are not offer any benefits</span>
								</div>
							)}
						</div>
						<div className={classes.deliveryAndAccessContainer}>
							<p className={classes.deliveryOptions} style={{ textAlign: "center" }}>
								Delivery Options
							</p>
							{restaurant.labelled && restaurant.labelled.delivery && restaurant.labelled.delivery.length > 0 ? (
								<div className={classes.deliverAvatarContainer}>
									{restaurant.labelled.delivery.map((deliver) => {
										console.log(JSON.stringify(deliver.delivery));
										console.log(otherFeatures[deliver.delivery]);
										return (
											<Avatar
												style={{
													width: "50px",
													height: "50px",
													marginLeft: "20px",
												}}
												src={otherFeatures[deliver.delivery].logo}
												id={otherFeatures[deliver.delivery]}></Avatar>
										);
									})}
								</div>
							) : (
								<p style={{ textAlign: "center" }}>Delivery options not available</p>
							)}

							<p className={classes.accessibilityOptions} style={{ textAlign: "center" }}>
								Accessibility Options
							</p>
							{!restaurant.tags.some((x) => x) ? (
								<p style={{ textAlign: "center" }}>Accessibility options not available </p>
							) : (
								<div className={classes.accessibilityIconsContainer} style={{ justifyContent: "center" }}>
									{restaurant.tags[0] !== false ? (
										<AccessibleIcon
											style={{
												position: "relative",
												color: "#85B858",
												height: "50px",
												width: "50px",
											}}
										/>
									) : null}
									{restaurant.tags[1] !== false ? (
										<img
											src={Gluten}
											style={{
												position: "relative",
												height: "50px",
												width: "50px",
											}}
										/>
									) : null}
									{restaurant.tags[2] !== false ? (
										<div
											style={{
												borderRadius: "50%",
												backgroundColor: "white",
												border: "3px solid #85B858",
												position: "relative",
												height: "50px",
												width: "50px",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												marginLeft: "20px",
											}}>
											<p style={{ textAlign: "center", fontSize: "10px", fontWeight: "bold", color: "#85B858" }}>100% Vegan</p>
										</div>
									) : null}
								</div>
							)}
						</div>
						{restaurant.contact && <div className={classes.contactContainer} style={{ width: "90%", alignItems: "center" }}>
							<p className={classes.contact} style={{ textAlign: "center" }}>
								Contact
							</p>
							<TextField
								className={classes.textField}
								label="Name Surname"
								type="text"
								defaultValue={restaurant.contact.contact_name}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								InputLabelProps={{
									style: { color: "#c8678d" },
								}}
							/>
							<TextField
								className={classes.textField}
								label="role"
								type="text"
								defaultValue={restaurant.contact.role}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								InputLabelProps={{
									style: { color: "#c8678d" },
								}}
							/>
							<TextField
								className={classes.textField}
								label="Email"
								type="text"
								defaultValue={restaurant.contact.email}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								InputLabelProps={{
									style: { color: "#c8678d" },
								}}
							/>
							<TextField
								className={classes.textField}
								style={{ marginBottom: "40px" }}
								label="Phone number"
								type="text"
								defaultValue={restaurant.contact.phone_number}
								variant="standard"
								inputProps={{ readOnly: true, disabled: true }}
								// InputLabelProps={{
								//   style: { color: "#c8678d" },
								// }}
							/>
						</div>}
						<div className={classes.categories} style={{ alignItems: "center" }}>
							<p className={classes.category} style={{ textAlign: "center" }}>
								Category
							</p>
							<div className={classes.tagsContainer}>
								{restaurant.rest_categories && restaurant.rest_categories.map((category) => {
									return <Chip key={category.id} label={category.category_name} className={classes.chip} />;
								})}
							</div>
						</div>
						<div
							className={classes.openingTimesContainer}
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<p className={classes.openingTimes}>Opening Times</p>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								{restaurant.schedule.always_open ? <p>Open 24/7 </p> : <div>{getDayShifts}</div>}
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	return changeUiByViewPortWidth();
}
