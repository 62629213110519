import React, { useState, useEffect, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@mui/material/Alert";
import * as config from "../../config";
import DailyOpening from "./DailyOpening";
import { FormControlLabel, Switch } from "@material-ui/core";
import "./schedule.css";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function Schedule(props) {
	const useStyles = makeStyles((theme) => ({
		scrollerContainer: {
			"&::-webkit-scrollbar": {
				width: "1px",
				height: "5px",
			},
			"& > .li-item": {
				marginTop: "10px",
			},
			"&::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"&::-webkit-scrollbar-thumb": {
				background: " #888",
				borderRadius: "50px",
			},
			"&::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		},
	}));
	const classes = useStyles();
	const scroller = createRef(null);
	const { schedule } = props;
	const [selected, setSelected] = useState("Tuesday");

	const releseAlert = () => {
		if (props.alert && props.alert.alertErr && props.alert.alertErr.time)
			props.setAlert((prevState) => {
				let alertErr = Object.assign({}, prevState.alertErr);
				alertErr.time = false;
				return { alertErr };
			});
	};

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const Schedule = () => {
		return config.weekDays.map((weekDay, index) => {
			return (
				<div
					key={index}
					className={width > 1199 ? classes.scrollerContainer : classes.container}
					style={
						width > 1199 && weekDay.name == "Sunday"
							? {
									flex: 1,
									display: "inline-flex",
									alignItems: "flex-start",
									paddingTop: "30px",
									paddingBottom: "10px",
									marginBottom: "50px",
									overflowX: "auto",
									overflowY: "hidden",
									width: "100%",
							  }
							: width > 1199
							? {
									flex: 1,
									display: "inline-flex",
									alignItems: "flex-start",
									paddingTop: "30px",
									paddingBottom: "10px",
									overflowX: "auto",
									overflowY: "hidden",
									width: "100%",
							  }
							: {
									display: "flex",
									alignItems: "center",
									paddingTop: "30px",
									paddingBottom: "10px",
									flexDirection: "column",
									width: "100%",
							  }
					}>
					<DailyOpening onScheduleChange={(action, payload) => onScheduleChange(action, (index + 1) % 7, payload)} ref={scroller} key={index} key={weekDay.name} dayName={weekDay.name} shifts={schedule && schedule[index] ? schedule[index].shifts : []} schedule={schedule} loader={props.loader} />
				</div>
			);
		});
	};
	const scheduleItems = Schedule();

	const onScheduleChange = (action, weekDay, payload) => {
		const { schedule, alwaysOpen, loader } = props;

		let newSchedule = {};
		const { id, shift } = payload;

		switch (action) {
			case "DELETE":
				console.log("id, weekDay: " + id + "  " + weekDay);
				newSchedule = [
					...schedule.map((opening) => {
						const deletedShiftIndex = opening.shifts.findIndex((s) => s.id === id);
						if (deletedShiftIndex === 0 && opening.shifts.length > 1) {
							Object.assign(opening.shifts[1], { active: true });
						}
						console.log(deletedShiftIndex + " " + opening.shifts[1]);
						return opening.from_day == weekDay
							? {
									...opening,
									shifts: [...opening.shifts.filter((shift) => shift.id !== id)],
							  }
							: opening;
					}),
				];
				console.log("after delete " + JSON.stringify(newSchedule));

				break;
			case "ADD":
				console.log("on add with this weekday and shift: " + weekDay + " " + JSON.stringify(shift));
				newSchedule = [
					...schedule.map((opening) => {
						return opening.from_day == weekDay ? { ...opening, shifts: [...opening.shifts, shift] } : opening;
					}),
				];
				console.log("after add " + JSON.stringify(newSchedule));

				break;
			case "UPDATE":
				newSchedule = [
					...schedule.map((opening) => {
						return opening.from_day == weekDay
							? {
									...opening,
									shifts: [...opening.shifts.map((s) => (s.id === shift.id ? shift : s))],
							  }
							: opening;
					}),
				];
				break;
		}

		props.handleInput("schedule", newSchedule);
	};

	const { alwaysOpen, handleInput } = props;

	const changeUiByViewPortWidth = () => {
		const { alert } = props;

		if (width > 1199) {
			return (
				<div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignSelf: "flex-start",
						}}>
						<p
							style={{
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
								marginTop: 0,
							}}>
							Business Hours
						</p>

						<div style={{ display: "flex", paddingLeft: "10px" }}>
							<FormControlLabel
								control={
									<Switch
										checked={alwaysOpen}
										name="alwaysOpen"
										onChange={(event) => {
											handleInput("alwaysOpen", event.target.checked);
											releseAlert();
										}}
									/>
								}
								label="Open 24/7"
							/>
						</div>
					</div>

					{props.alert.alertErr.time ? (
						<Alert style={{ width: "50%" }} severity="error">
							Choose opening times
						</Alert>
					) : null}
					<br />
					{!alwaysOpen && (
						<div style={{ display: "flex", width: "100%" }}>
							<div
								className={scheduleItems}
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									alignItems: "flex-start",
									width: "90%",
								}}>
								{scheduleItems}
							</div>
						</div>
					)}
				</div>
			);
		} else {
			return (
				<div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignSelf: "flex-start",
						}}>
						<p
							style={{
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
								marginTop: "50px",
								textAlign: "center",
							}}>
							Business Hours
						</p>

						<div style={{ display: "flex", justifyContent: "center" }}>
							<FormControlLabel control={<Switch checked={alwaysOpen} name="alwaysOpen" onChange={((event) => handleInput("alwaysOpen", event.target.checked), releseAlert())} />} label="Open 24/7" />
						</div>
					</div>
					{props.alert && props.alert.alertErr && props.alert.alertErr.time ? <Alert severity="error">Choose opening times</Alert> : null}
					<br />
					{!alwaysOpen && (
						<div style={{ display: "flex", width: "100%" }}>
							<div
								className={scheduleItems}
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									alignItems: "flex-start",
									width: "100%",
								}}>
								{scheduleItems}
							</div>
						</div>
					)}
				</div>
			);
		}
	};

	return changeUiByViewPortWidth();
}
