import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@mui/material/CircularProgress";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export default function ContactInfo(props) {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    root: {
      padding: theme.spacing(3, 2),
    },
    container: {
      marginTop: "3%",
      display: "flex",
      width: "90%",
      justifyContent: "flex-start",
      flexDirection: "row",
    },

    header: {
      color: "#707070",
      fontSize: "24px",
      fontWeight: "bold",
    },
    textField: {
      display: "flex",
      flexDirection: "column",
      width: "30%",
    },
    Input: {},
  }));

  const classes = useStyles();

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setValidEmail(true);
    }
    return re.test(String(email).toLowerCase());
  };

  const { height, width } = useWindowDimensions();

  const { formInput, handleInput } = props;
  const [validEmail, setValidEmail] = useState(false);

  const changeUiByViewPortWidth = () => {
    if (width > 1199) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            width: "90vw",
            padding: "0",
            height: "70vh",
            backgroundColor: "white",
            borderRadius: "30px",
            zIndex: 3,
          }}
        >
          {props.loader ? (
            <div
              style={{
                position: "fixed",
                display: "block",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress size={200} />
              </div>
            </div>
          ) : null}
          {/* {props.errorMessage && (
            <Alert style={{ marginTop: "50px" }} severity="error">
              {props.errorMessage}
            </Alert>
          )} */}
          <div className={classes.container}>
            <div className={classes.textField}>
              <Typography
                variant="h2"
                component="div"
                className={classes.header}
              >
                Contact Info
              </Typography>
              <TextField
                className={classes.Input}
                style={{ marginTop: "30px" }}
                label="Full name"
                value={formInput.contactName}
                color="primary"
                onChange={(evt) => handleInput("contactName", evt.target.value)}
                
                error={
                  !formInput.contactName && props.formInput.contactNameError
                }
                helperText={
                  !formInput.contactName && props.formInput.contactNameError
                }
              />
              <TextField
                className={classes.Input}
                label="Role"
                value={formInput.role}
                color="primary"
                onChange={(evt) => handleInput("role", evt.target.value)}
                error={!formInput.role && props.formInput.roleError}
                helperText={!formInput.role && props.formInput.roleError}
              />
              <TextField
                className={classes.Input}
                label="Email"
                name="email"
                color="primary"
                onChange={(evt) => {
                  handleInput("email", evt.target.value);
                  validateEmail(evt.target.value);
                }}
                
                type="email"
                value={props.formInput.email}
                error={!validEmail ? props.formInput.emailError : null}
                helperText={!validEmail ? props.formInput.emailError : null}
              />
              <TextField
                type="tel"
                inputProps={{ maxLength: 15, minLength: 7 }}
                className={classes.Input}
                label="Phone number"
                name="phone number"
                color="primary"
                onChange={(evt) =>
                  handleInput("contactNumber", evt.target.value)
                }
                
                value={props.formInput.contactNumber}
                error={
                  !props.formInput.contactNumber &&
                  props.formInput.contactNumberError
                }
                helperText={
                  !props.formInput.contactNumber &&
                  props.formInput.contactNumberError
                }
              />
            </div>
          </div>
        </div>
      );
    } else if (width <= 1199) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            width: "90vw",
            padding: "0",
            height: "100%",
            backgroundColor: "white",
            borderRadius: "30px",
            zIndex: 3,
          }}
        >
          {props.loader ? (
            <div
              style={{
                position: "fixed",
                display: "block",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress size={200} />
              </div>
            </div>
          ) : null}
          {/* {props.errorMessage && (
            <Alert style={{ marginTop: "50px" }} severity="error">
              {props.errorMessage}
            </Alert>
          )} */}
          <div
            className={classes.container}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div
              className={classes.textField}
              style={{ width: "90%", alignItems: "cetner" }}
            >
              <Typography
                variant="h2"
                component="div"
                className={classes.header}
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                Contact Info
              </Typography>
              <TextField
                className={classes.Input}
                style={{ marginTop: "30px" }}
                label="Full name"
                value={formInput.contactName}
                color="primary"
                onChange={(evt) => handleInput("contactName", evt.target.value)}
                
                error={
                  !formInput.contactName && props.formInput.contactNameError
                }
                helperText={
                  !formInput.contactName && props.formInput.contactNameError
                }
              />
              <TextField
                className={classes.Input}
                label="Role"
                value={formInput.role}
                color="primary"
                onChange={(evt) => handleInput("role", evt.target.value)}
                
                error={!formInput.role && props.formInput.roleError}
                helperText={!formInput.role && props.formInput.roleError}
              />
              <TextField
                className={classes.Input}
                label="Email"
                name="email"
                color="primary"
                onChange={(evt) => handleInput("email", evt.target.value)}
                
                type="email"
                value={props.formInput.email}
                error={
                  !props.formInput.email &&
                  props.validateContact &&
                  props.formInput.emailError
                }
                helperText={
                  !props.formInput.email &&
                  props.validateContact &&
                  props.formInput.emailError
                }
              />
              <TextField
                type="tel"
                inputProps={{ maxLength: 15, minLength: 7 }}
                className={classes.Input}
                label="Phone number"
                name="phone number"
                color="primary"
                onChange={(evt) =>
                  handleInput("contactNumber", evt.target.value)
                }
                
                value={props.formInput.contactNumber}
                error={
                  !props.formInput.contactNumber &&
                  props.formInput.contactNumberError
                }
                helperText={
                  !props.formInput.contactNumber &&
                  props.formInput.contactNumberError
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };
  return changeUiByViewPortWidth();
}
