import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PlacesList from "./PlacesList";
import Api from "./../api/index";
import { VF_COLORS, usStates } from "../config";
import { ArrowForward } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

export default function FindRestaurant(props) {
	const useStyles = makeStyles((theme) => ({
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
			width: "100%",
			color: VF_COLORS.primary
		},
		button: {
			margin: theme.spacing(1),
		},
		leftIcon: {
			marginRight: theme.spacing(1),
		},
		rightIcon: {
			marginLeft: theme.spacing(1),
		},
		iconSmall: {
			fontSize: 20,
		},
		root: {
			padding: theme.spacing(3, 2),
		},
		container: {
			[theme.breakpoints.up("smPhone")]: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",

				width: "100%",
			},
			[theme.breakpoints.up("lgPhone")]: {
				display: "flex",
				justifyContent: "center",
				width: "100%",
			},
			[theme.breakpoints.up("ipad")]: {
				display: "flex",
				justifyContent: "center",
				width: "100%",
			},
			[theme.breakpoints.up("smPc")]: {
				width: "100%",
				display: "flex",
				alignItems: "start",
				justifyContent: "start",
			},
		},
		inputField: {
			[theme.breakpoints.up("smPhone")]: { width: "90%", paddingLeft: 0 },
			[theme.breakpoints.up("lgPhone")]: { width: "90%", paddingLeft: 0 },
			[theme.breakpoints.up("ipad")]: {
				width: "90%",
				paddingLeft: 0,
			},
			[theme.breakpoints.up("smPc")]: {
				width: "60%",
				paddingLeft: "5%",
			},
			[theme.breakpoints.down("lgPc")]: {
				width: "100%",
				paddingLeft: "5%",
			},
			display: "flex",
			justifyContent: "center",
			position: "relative",
			paddingLeft: "7%",
			maxWidth: "1200px",
		},
		dropDown: {
			[theme.breakpoints.up("smPhone")]: {
				width: "90%",
			},
			[theme.breakpoints.up("lgPhone")]: {
				width: "90%",
			},
			[theme.breakpoints.up("ipad")]: {
				paddingLeft: 0,
			},
			[theme.breakpoints.up("smPc")]: {
				width: "60%",
				paddingLeft: "5%",
			},
			[theme.breakpoints.down("lgPc")]: {
				paddingLeft: "5%",
				width: "100%",
			},
			position: "relative",
			maxWidth: "1200px",
		},
		textField: {
			[theme.breakpoints.up("smPhone")]: { height: "50px", fontSize: "20px" },
			[theme.breakpoints.up("lgPhone")]: { height: "50px", fontSize: "20px" },
			[theme.breakpoints.up("ipad")]: { height: "70px", fontSize: "20px" },
			[theme.breakpoints.up("smPc")]: { height: "70px", fontSize: "30px" },
			[theme.breakpoints.down("lgPc")]: { height: "70px", fontSize: "30px" },
			width: "100%",
			height: "70px",
			left: "10%",
			alignSelf: "left",
			borderRadius: "50px",
			"&::-webkit-input-placeholder": {
				[theme.breakpoints.up("smPhone")]: { fontSize: "20px" },
				[theme.breakpoints.up("lgPhone")]: { fontSize: "20px" },
				[theme.breakpoints.up("ipad")]: { fontSize: "20px" },
				[theme.breakpoints.up("smPc")]: { fontSize: "30px" },
				[theme.breakpoints.down("lgPc")]: { fontSize: "30px" },
			},

			border: "1px solid #00000026",
			"&:focus": {
				outline: "none",
			},
			color: "#777777",
			"&::placeholder": {
				color: "#00000029",
			},
			boxSizing: "border-box",
			paddingLeft: "30px",
			zIndex: "2",
		},
		submitButton: {
			[theme.breakpoints.up("smPhone")]: { height: "50px", width: "70px" },
			[theme.breakpoints.up("lgPhone")]: { height: "50px", width: "70px" },
			[theme.breakpoints.up("ipad")]: { height: "70px", width: "120px" },
			[theme.breakpoints.up("smPc")]: { height: "70px", width: "120px" },
			[theme.breakpoints.down("lgPc")]: { height: "70px", width: "120px" },
			position: "absolute",
			right: "0",
			top: "0",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: "40px",
			zIndex: "2",
			border: "none",
			"&:hover": {
				backgroundColor: VF_COLORS.primary,
			},
			backgroundColor: VF_COLORS.secondary,
		},
		submitArrowIcon: {
			display: "flex",
			position: "relative",
			width: "30px",
			height: "30px",
			color: "white",
			zIndex: "2",
		},
		noPlaceButton: {
			border: "none",
			backgroundColor: "inherit",
			margin: "10px",
			padding: "14px",
			fontSize: "16px",
			cursor: "pointer",
			display: "inline-block",
			"&:hover": {
				backgroundColor: VF_COLORS.primary,
			},
		},
	}));

	const [places, setPlaces] = React.useState([]);
	const [name, setName] = React.useState("");
	const [country, setCountry] = React.useState('US');
	const [selectedState, setSelectedState] = React.useState(usStates.find(s => s.abbreviation == 'NY'));

	const [showNoPlaceButton, setShowNoPlaceButton] = React.useState(false);
	const [loader, setLoader] = React.useState(false);
	//TDL (IF FALSE (test = false) CHANGE INPUT STATE BEFORE DEPLOY
	const [inputDisabled, setInputDisabled] = React.useState(true);
	// TDL ENABLE BEFORE DEPLOY
	useEffect(() => {
		if (props.captcha.length) {
			setInputDisabled(false);
		} else {
			setInputDisabled(true);
		}
	}, [props.captcha]);

	useEffect(() => {
		setLoader(true);
		console.log(name);
		const debounceTimer = setTimeout(async () => {
			if (name.length > 0) {
				const places = await Api.getPlaces(name, country !== 'US' ? country : selectedState.abbreviation);
				if (places) {
					console.log("success!");
					setPlaces(places);
					setLoader(false);
				}
				setShowNoPlaceButton(true);
			}
		}, 500);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, [name, country, selectedState]);

	const onPlaceClick = async (placeId) => {
		console.log("onPlaceClick " + placeId);
		props.setRestaurantId(placeId);

		props.undoErrorMessage();
	};

	const classes = useStyles();

	return (
		<div className={classes.container}>
			{props.errorMessage && <label style={{ color: "red" }}>{props.errorMessage}</label>}
			<div className={classes.inputField}>
				<FormControl className={classes.formControl}>
					<InputLabel id="demo-muti	ple-chip-label">
						Choose country
					</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={country}
						label="Country"
						disabled={inputDisabled}
						onChange={(e) => {
							!inputDisabled && setCountry(e.target.value);
							!inputDisabled && props.handleInput("country", e.target.value);
						}}
					>
						<MenuItem value={'IL'}>Israel</MenuItem>
						<MenuItem value={'GB'}>United Kingdom</MenuItem>
						<MenuItem value={'US'}>United States</MenuItem>
					</Select>
				</FormControl>
			</div>
			{country === 'US' && <div className={classes.inputField}>
				<FormControl className={classes.formControl}>
					<InputLabel id="demo-mutiple-chip-label">
						Choose state
					</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedState.abbreviation}
						label="State"
						disabled={inputDisabled}
						onChange={(e) => {
							const newState = usStates.find(s => e.target.value === s.abbreviation); 
							!inputDisabled && setSelectedState(newState);
							!inputDisabled && props.handleInput("state", newState.abbreviation);
						}}
					>
						{usStates.map(s => (<MenuItem value={s.abbreviation}>{s.name}</MenuItem>))}
					</Select>
				</FormControl>
			</div>}
			<div className={classes.inputField}>

				<input
					value={inputDisabled ? "" : name}
					type="text"
					placeholder="Restaurant name"
					className={classes.textField}
					disabled={inputDisabled}
					onChange={(e) => {
						!inputDisabled && setName(e.target.value);

						!inputDisabled && props.handleInput("restaurant_name", e.target.value);
					}}
					required></input>
				<button
					className={classes.submitButton}
					//TDL!!!!
					onClick={() => {
						if (!inputDisabled) {
							props.onClickNoPlaceButton();
							props.setFormIsTouched(false);
							console.log("clicked");
							props.formInputInit();
							props.handleInput("restaurant_name", name);
						}
					}}
					disabled={inputDisabled}>
					<ArrowForward className={classes.submitArrowIcon} />
				</button>
			</div>

			{places && places.length > 0 && name.length > 0 && (
				<div className={classes.dropDown}>
					<PlacesList loader={loader} places={places} onPress={onPlaceClick && props.setFormIsTouched(false)} setRestaurantId={(id) => props.setRestaurantId(id)} setFormIsTouched={props.setFormIsTouched} />
				</div>
			)}
			{showNoPlaceButton && (
				<button className={classes.noPlaceButton} onClick={props.onClickNoPlaceButton}>
					Can't find your restaurant? Click here
				</button>
			)}
		</div>
	);
}
