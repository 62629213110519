import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { VF_COLORS } from "../config";
import { makeStyles } from "@material-ui/core/styles";
import vflogo from "../assets/vflogo.png";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
	pTag: {
		fontSize: "22px",
		color: "#707070",
		textAlign: "center",
		letterSpacing: 0,
		margin: 0,
	},
	CellpTag: {
		fontSize: "15px",
		color: "#707070",
		textAlign: "center",
		letterSpacing: 0,
		margin: 0,
	},
	vflogo: {
		backgroundImage: `url(${vflogo})`,
		height: "197px",
		width: "168px",
		zIndex: "3",
		position: "relative",
		objectFit: "cover",
		backgroundRepeat: "no-repeat",
		top: "5%",
		left: "5%",
	},
}));

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function Finish({ error }) {
	const classes = useStyles();
	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		console.log(error);

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		useEffect(() => {
			if (error) {
				console.log("server error");
			}
		}, [error]);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						position: "relative",
						width: "90vw",
						padding: "0",
						height: "80vh",
						backgroundColor: "white",
						borderRadius: "30px",
						justifyContent: "center",
						zIndex: 3,
					}}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						{error ? (
							<AddIcon
								style={{
									height: "100px",
									width: "100px",
									transform: "rotate(45deg)",
									color: "#C8678D",
								}}
							/>
						) : (
							<CheckCircleIcon
								style={{
									height: "100px",
									width: "100px",
									color: "#77AC42",
								}}
							/>
						)}
						<p
							style={{
								fontSize: "28px",
								color: "#77AC42",
								textAlign: "center",
								letterSpacing: 0,
							}}>
							{error ? "Sorry, an error had occurred!" : "Thank you for completing the form!"}
						</p>
						<p className={classes.pTag}>{error ? "Please try again later." : "Our team will take a look at it as soon as possible"}</p>
						<p className={classes.pTag}>{error ? "If the error still occurs, kindly contact us on:" : "and your business should be added to the app within the next 2 weeks."}</p>
						<p className={classes.pTag}>{error ? "tech-service@vegan-friendly.com" : "Have a great day!"}</p>
						<div className={classes.vflogo}></div>
					</div>
				</div>
			);
		} else {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						position: "relative",
						width: "90vw",
						padding: "0",
						height: "80vh",
						backgroundColor: "white",
						borderRadius: "30px",
						justifyContent: "center",
						zIndex: 3,
					}}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						{error ? (
							<AddIcon
								style={{
									height: "60px",
									width: "60px",
									transform: "rotate(45deg)",
									color: "#C8678D",
								}}
							/>
						) : (
							<CheckCircleIcon
								style={{
									height: "60px",
									width: "60px",
									color: "#77AC42",
								}}
							/>
						)}
						<p
							style={{
								fontSize: "15px",
								color: "#77AC42",
								textAlign: "center",
								letterSpacing: 0,
							}}>
							{error ? "Sorry, an error had occurred!" : "Thank you for completing the form!"}{" "}
						</p>
						<p className={classes.CellpTag}>{error ? "Please try again later." : "Our team will take a look at it as soon as possible"} </p>
						<p className={classes.CellpTag}>{error ? "If the error still occurs, kindly contact us on:" : "and your business should be added to the app within the next 2 weeks."}</p>
						<p className={classes.CellpTag}> {error ? "tech-service@vegan-friendly.com" : "Have a great day!"}</p>
						<div className={classes.vflogo}></div>
					</div>
				</div>
			);
		}
	};
	return changeUiByViewPortWidth();
}
