import React, { useState, useEffect, useRef, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import DateAdapter from "@mui/lab/AdapterMoment";
import AddIcon from "@material-ui/icons/Add";
import { VF_COLORS } from "../../config";
import { JssProvider } from "react-jss";

import "./Shift.css";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function Shift(props) {
	const useStyles = makeStyles((theme) => ({
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 400,
		},
		closed: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			color: "grey",
		},

		label: {
			fontSize: "16px",
			marginRight: 10,
			width: "50px",
			display: "block",
			alignSelf: "center",
		},

		shift: {
			display: "flex",
			flexWrap: "nowrap",
			alignItems: "center",
		},

		shiftTime: {
			display: "flex",
			flexDirection: "row",
			width: "110px",
			marginRight: "20px",
		},

		timeChooser: {
			borderRadius: 10,
			margin: theme.spacing(1),
			"&:hover": {
				borderColor: VF_COLORS.primary,
			},

			focused: {
				borderColor: VF_COLORS.primary,
			},
		},
		removeIcon: {
			transform: "rotate(45deg)",
			color: "white",
		},
	}));

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const classes = useStyles();
	const fromRef = useRef(null);
	const toRef = useRef(null);

	const { shiftExist, changeSwitch, onShiftChange, from, to, id, active } = props;
	const changeSwitchOnLastShiftDelete = () => {
		if (shiftExist <= 1) {
			changeSwitch();
			props.onShiftDelete(id);
		} else {
			props.onShiftDelete(id);
		}
	};
	let fromFormatted;
	let toFormatted;

	if (typeof from === "object" || typeof to === "object") {
		console.log("string");
		fromFormatted = from;
		toFormatted = to;
	} else if ((typeof from === "string" && from !== undefined) || (typeof to === "string" && to !== undefined)) {
		if (from !== undefined) {
			const fromD = new Date("August 19, 1975 23:15:30");
			const fromHour = from.split(":").slice(0, 1)[0];
			const fromMin = from.split(":").slice(1)[0];
			fromD.setHours(fromHour, fromMin);
			fromFormatted = fromD;
		}
		if (to !== undefined) {
			const toD = new Date("August 19, 1975 23:15:30");
			const toHour = to.split(":").slice(0, 1)[0];
			const toMin = to.split(":").slice(1)[0];
			toD.setHours(toHour, toMin);
			toFormatted = toD;
		}
	}

	return (
		<div className={classes.shift} style={width <= 1199 ? { marginBottom: "20px" } : null}>
			<div className={classes.shiftTime}>
				<LocalizationProvider dateAdapter={DateAdapter}>
					<TimePicker
						ampm={false}
						inputFormat="HH:mm"
						key={"From"}
						label="From"
						value={from ? moment(fromFormatted) : null}
						ref={() => fromRef}
						onChange={(evt) => {
							console.log(evt);
							onShiftChange({
								id,
								to,
								from: moment(evt).local().format("HH:mm"),
								active,
							});
						}}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
			</div>
			<div className={classes.shiftTime}>
				<JssProvider classNamePrefix="Material-ui-pickers-">
					<LocalizationProvider dateAdapter={DateAdapter}>
						<TimePicker
							ampm={false}
							inputFormat="HH:mm"
							key={"To"}
							label="To"
							value={to ? moment(toFormatted) : null}
							ref={() => toRef}
							onChange={(evt) =>
								onShiftChange({
									id,
									from,
									to: moment(evt).local().format("HH:mm"),
									active,
								})
							}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</JssProvider>
			</div>
			<IconButton style={{ backgroundColor: "#929292", width: "15px", height: "15px" }} onClick={() => changeSwitchOnLastShiftDelete()}>
				<AddIcon className={classes.removeIcon}></AddIcon>
			</IconButton>
		</div>
	);
}
