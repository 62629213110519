import axios from "axios";
import * as config from "../config";

let token = null; // config.token;
const { credentials, test } = config;
//TDL CHANGE TO PROD
const APP_URL = test ? "https://test.appadmin.vegan-friendly.co.uk" : "https://appadmin.vegan-friendly.co.uk";
class Api {
	async login() {
		const requestUrl = `${APP_URL}/auth/local`;
		const _headers = {
			"Content-Type": "application/json",
			Accept: "application/json",
		};

		const headers = Object.assign({}, _headers);

		const data = {
			identifier: credentials.identifier,
			password: credentials.password,
		};

		try {
			const response = await axios.post(requestUrl, data, {
				headers,
			});

			token = response.data.jwt;
		} catch (error) {
			console.log("error when trying to submit: " + JSON.stringify(error));
		}
	}

	submitRestaurant = async (restaurant) => {
		//TDL change /path to test server error
		const requestUrl = `${APP_URL}/restaurants`;
		console.log("trying to post with url: " + requestUrl);

		const _headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		};
		const headers = Object.assign({}, _headers);
		const data = restaurant;

		try {
			const response = await axios.post(requestUrl, data, {
				headers,
			});
			return response;
		} catch (error) {
			// Error 😨
			if (error.response) {
				/*
				 * The request was made and the server responded with a
				 * status code that falls out of the range of 2xx
				 */
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				return error;
			} else if (error.request) {
				/*
				 * The request was made but no response was received, `error.request`
				 * is an instance of XMLHttpRequest in the browser and an instance
				 * of http.ClientRequest in Node.js
				 */
				console.log(error.request);
				return error;
			} else {
				// Something happened in setting up the request and triggered an Error
				console.log("Error", error.message);
				return error;
			}
		}
	};

	sendEmail = async (restaurant) => {
		const requestUrl = `${APP_URL}/notify-mail-add-restaurant`;
		console.log("trying to post with url: " + requestUrl);

		const _headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		};
		const headers = Object.assign({}, _headers);
		const data = restaurant;

		try {
			const response = await axios.post(requestUrl, data, {
				headers,
			});
			return response;
		} catch (error) {
			console.log("error when trying to submit: " + JSON.stringify(error));
		}
	};

	getPlaces = async (name, country) => {
		const requestUrl = `${APP_URL}/restaurants-from-google/${name}/${country}`;

		console.log('this is the request url: ', requestUrl);
		
		const _headers = {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: "Bearer " + token,
		};

		const headers = Object.assign({}, _headers);
		const method = "GET";

		try {
			const response = await axios(requestUrl, {
				method,
				headers,
			});

			if (response && response.data && response.data.success) {
				return response.data.places;
			}
			return null;
		} catch (e) {
			console.log("error we got! " + e);
		}
	};

	getPlaceById = async (id) => {
		const requestUrl = `${APP_URL}/restaurant-from-google-by-id/${id}`;
		console.log("trying to get placeId with url: " + requestUrl);

		const _headers = {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: "Bearer " + token,
		};

		const headers = Object.assign({}, _headers);
		const method = "GET";
		try {
			const response = await axios(requestUrl, {
				method,
				headers,
			});

			console.log("this is response from index" + JSON.stringify(response));
			if (response && response.data && response.data.success) {
				return response.data;
			}
			return null;
		} catch (e) {
			console.log("error we got: " + e);
		}
	};

	filesUpload = async (file) => {
		const requestUrl = `${APP_URL}/upload`;
		console.log("trying to upload with url: " + requestUrl);

		const _headers = {
			"Content-Type": "multipart/form-data",
			Authorization: "Bearer " + token,
		};

		const headers = Object.assign({}, _headers);

		const formData = new FormData();

		//for(let file of files) {
		//  console.log('appending ' + file.name);
		formData.append("files", file);
		console.log(file);

		//}

		try {
			const response = await axios.post(requestUrl, formData, {
				headers,
			});

			if (response && response.data) {
				return response.data[0];
			} else {
				return null;
			}
		} catch (e) {
			console.log("error we got!! " + JSON.stringify(e));
		}
	};

	getCategories = async (name) => {
		const requestUrl = `${APP_URL}/restaurant-categories`;
		console.log("trying to get categories with url: " + requestUrl);

		const _headers = {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: "Bearer " + token,
		};

		const headers = Object.assign({}, _headers);
		const method = "GET";
		try {
			const response = await axios(requestUrl, {
				method,
				headers,
			});

			if (response && response.data) {
				console.log(response.data);
				return response.data;
			}
			return null;
		} catch (e) {
			console.log("error we got: " + e);
		}
	};

	getTags = async () => {
		const requestUrl = `${APP_URL}/tags`;
		console.log("trying to get tags with url: " + requestUrl);

		const _headers = {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: "Bearer " + token,
		};

		const headers = Object.assign({}, _headers);
		const method = "GET";
		try {
			const response = await axios(requestUrl, {
				method,
				headers,
			});

			if (response && response.data) {
				console.log(response.data);
				let newResponse = [];
				newResponse.push(response.data[0], response.data[2], response.data[5]);
				return newResponse;
			}
			return null;
		} catch (e) {
			console.log("error we got: " + e);
		}
	};
}

export default new Api();
