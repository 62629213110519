import React, { useState } from "react";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import VeganFriendlyForm from "./VeganFriendlyForm";
import styles from "./global.styles";
import * as config from "../config";

const useStyles = makeStyles((theme) => ({
  container: {
    // display: 'flex-',
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    height: window.innerHeight,
  },
  root: {
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },

  textField: {
    alignSelf: "center",
    display: "flex",
  },
}));
export default function Main(props) {
  const classes = useStyles();

  const [loggedIn, setLoggedIn] = useState(false);

  const onCodeChange = (evt) => {
    console.log("onCodeChange: " + evt.target.value);
    if (evt.target.value === config.entryCode) {
      setLoggedIn(true);
    }
  };

  return (
    <div className={classes.root} id="header">
      {false && (
        <div className={classes.container}>
          <TextField
            className={classes.textField}
            onChange={onCodeChange}
            helperText={"ENTRY CODE"}
          />
        </div>
      )}
      {true && (
        <VeganFriendlyForm
          id="vegan-friendly-form"
          setBackgroundImageDisplay={props.setBackgroundImageDisplay}
        />
      )}
    </div>
  );
}
