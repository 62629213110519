import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PhotoContainer from "./PhotoContainer";
import { Button, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { VF_COLORS } from "../../config";
import Alert from "@mui/material/Alert";
import pdfIcon from "../../assets/pdfIcon.png";

const FOOD_PICS = "FOOD_PICS";
const MENU_PICS = "MENU_PICS";
const LOGO = "LOGO";

const MENU_PICS_LIMIT = 5;
const FOOD_PICS_LIMIT = 5;

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function PhotoUploader(props) {
	const hiddenMenuPicsRef = useRef(null);
	const hiddenFoodPicsRef = useRef(null);
	const hiddenLogoRef = useRef(null);

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const useStyles = makeStyles((theme) => ({
		addBtn: {
			border: `1px solid #26626833`,
			borderRadius: "50%",
			width: "44px",
			height: "44px",
		},
		addSection: {
			display: "flex",
			alignItems: "center",
			height: "100px",
		},
		foodPics: {
			left: "0 !important",
		},
	}));
	const classes = useStyles();

	const styles = {
		photoUploadContainer: {
			display: "flex",
			flexDirection: "column",
			width: "50%",
			height: "95%",
			justifyContent: "flex-start",
			position: "relative",
			top: "5%",
			left: "5%",
		},
		photoContainer: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			flexWrap: "wrap",
		},
		choosePhotos: {
			backgroundColor: VF_COLORS.primary,
			color: "white",
			borderRadius: 25,
			width: 25,
		},

		choosePhotosDisabled: {
			backgroundColor: VF_COLORS.secondary,
			color: "white",
			borderRadius: 25,
			width: 25,
		},
	};
	const [imageName, setImageName] = useState("");

	const onChooseLogo = (event) => {
		event.preventDefault();

		const logo = {
			url: URL.createObjectURL(event.target.files[0]),
			id: Math.random(),
		};

		props.handleInput("logo", logo);
	};

	const onChooseMenuPics = (event) => {
		event.preventDefault();
		const menuPics = [...formInput.menuPics];

		if (menuPics.length + event.target.files.length > MENU_PICS_LIMIT) {
			alert(`you can choose up to ${FOOD_PICS_LIMIT} pictures`);
		} else {
			for (let file of event.target.files) {
				menuPics.push({
					file,
					url: URL.createObjectURL(file),
					id: Math.random(),
					name: file.name,
					extention: file.name.split(".").pop(),
				});
			}
			props.handleInput("menuPics", menuPics);
		}
	};

	const onChooseHiddenLogo = (event) => {
		hiddenLogoRef.current.click();
	};

	const onChooseHiddenFoodPics = (event) => {
		hiddenFoodPicsRef.current.click();
	};

	const onChooseHiddenMenuPics = (event) => {
		hiddenMenuPicsRef.current.click();
	};

	const onChooseFoodPics = async (event) => {
		event.preventDefault();

		const foodPics = [...formInput.foodPics];

		if (foodPics.length + event.target.files.length > FOOD_PICS_LIMIT) {
			alert(`you can choose up to ${FOOD_PICS_LIMIT} pictures`);
		} else {
			for (let file of event.target.files) {
				console.log(file);
				foodPics.push({
					file,
					url: URL.createObjectURL(file),
					id: Math.random(),
					name: file.name,
					extention: file.name.split(".").pop(),
				});
			}
		}

		props.handleInput("foodPics", foodPics);
	};

	const onChangePhotos = (action, type, id) => {
		console.log(`action ${action}, type: ${type}, id: ${id}`);

		switch ((action, type)) {
			case ("DELETE", "MENU_PICS"):
				props.handleInput("menuPics", [...formInput.menuPics.filter((pic) => pic.id !== id)]);
				break;
			case ("DELETE", "FOOD_PICS"):
				props.handleInput("foodPics", [...formInput.foodPics.filter((pic) => pic.id !== id)]);
				break;
			case ("DELETE", "LOGO"):
				props.handleInput("logo", null);
				break;
		}
	};

	const { formInput } = props;

	const disableMenuPics = formInput.menuPics.length === MENU_PICS_LIMIT;
	const disableFoodPics = formInput.foodPics.length === FOOD_PICS_LIMIT;
	const disableLogoPic = formInput.logo && formInput.logo.url;

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-start",
						position: "relative",
						width: "90vw",
						padding: "0",
						height: "100%",
						minHeight: "70vh",
						backgroundColor: "white",
						borderRadius: "30px",
						zIndex: 3,
					}}>
					<div style={styles.photoUploadContainer}>
						{props.alert.alertErr.menuPics ? (
							<Alert style={{ width: "50%", position: "relative" }} severity="error">
								Choose menu pictures
							</Alert>
						) : null}
						<div className={classes.addSection}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									lineHeight: 1,
								}}>
								<h3
									style={{
										minWidth: "100px",
										color: "#707070",
										marginBottom: 0,
										marginRight: "50px",
									}}>
									MENU PHOTOS
								</h3>
							</div>
							<IconButton className={classes.addBtn} disabled={disableMenuPics} onClick={onChooseHiddenMenuPics}>
								{formInput.menuPics.length !== 5 ? <AddIcon color="secondary" style={{ width: "30px", height: "30px" }}></AddIcon> : <CheckIcon color="secondary" style={{ width: "30px", height: "30px" }}></CheckIcon>}
							</IconButton>
							<input
								ref={hiddenMenuPicsRef}
								id="menu-pics-chooser"
								type="file"
								onChange={onChooseMenuPics}
								multiple
								accept="image/*,application/pdf"
								style={{ display: "none" }}
								onClick={(event) => {
									event.target.value = null;
								}}
							/>
						</div>

						<div style={styles.photoContainer}>
							{formInput.menuPics.map((preview) => (
								<PhotoContainer preview={preview.extention !== "pdf" ? preview.url : pdfIcon} onChangePhotos={(action, id) => onChangePhotos(action, MENU_PICS, id)} type={MENU_PICS} id={preview.id} name={preview.name} />
							))}
						</div>
					</div>
					<div style={styles.photoUploadContainer} className={classes.foodPics}>
						{props.alert.alertErr.foodPics ? (
							<Alert style={{ width: "50%", position: "relative" }} severity="error">
								Choose food pictures
							</Alert>
						) : null}
						<div className={classes.addSection}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginRight: "50px",
								}}>
								<h3
									style={{
										minWidth: "100px",
										color: "#707070",
										marginBottom: 0,
									}}>
									COVER PHOTOS
								</h3>
								<p
									style={{
										margin: 0,
										color: "#AAAAAA",
										fontSize: "12px",
										maxWidth: "300px",
									}}>
									We use the first picture you upload as the cover picture to your business, so upload something nice :)
								</p>
							</div>
							<IconButton className={classes.addBtn} disabled={disableFoodPics} onClick={onChooseHiddenFoodPics}>
								{formInput.foodPics.length !== 5 ? <AddIcon color="secondary" style={{ width: "30px", height: "30px" }}></AddIcon> : <CheckIcon color="secondary" style={{ width: "30px", height: "30px" }}></CheckIcon>}
							</IconButton>
							<input
								ref={hiddenFoodPicsRef}
								onChange={onChooseFoodPics}
								id="food-pics-chooser"
								type="file"
								accept="image/*,application/pdf"
								multiple
								style={{ display: "none" }}
								onClick={(event) => {
									event.target.value = null;
								}}
							/>
						</div>
						<div style={styles.photoContainer}>
							{formInput.foodPics.map((preview) => (
								<PhotoContainer preview={preview.extention !== "pdf" ? preview.url : pdfIcon} onChangePhotos={(action, id) => onChangePhotos(action, FOOD_PICS, id)} type={FOOD_PICS} id={preview.id} name={preview.name} />
							))}
						</div>
					</div>
				</div>
			);
		} else if (width <= 1199) {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						position: "relative",
						width: "90vw",
						padding: "0",
						height: "100%",
						backgroundColor: "white",
						borderRadius: "30px",
						zIndex: 3,
					}}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							height: "100%%",
							justifyContent: "center",
							alignItems: "center",

							position: "relative",
						}}>
						{props.alert.alertErr.menuPics ? (
							<Alert style={{ width: "50%", position: "relative" }} severity="error">
								Choose menu pictures
							</Alert>
						) : null}
						<div className={classes.addSection}>
							<h3 style={{ minWidth: "100px", color: "#707070" }}>MENU PHOTOS</h3>
							<IconButton className={classes.addBtn} disabled={disableMenuPics} onClick={onChooseHiddenMenuPics}>
								{formInput.menuPics.length !== 5 ? <AddIcon color="secondary" style={{ width: "30px", height: "30px" }}></AddIcon> : <CheckIcon color="secondary" style={{ width: "30px", height: "30px" }}></CheckIcon>}
							</IconButton>

							<input
								ref={hiddenMenuPicsRef}
								id="menu-pics-chooser"
								type="file"
								onChange={onChooseMenuPics}
								multiple
								accept="image/*,application/pdf"
								style={{ display: "none" }}
								onClick={(event) => {
									event.target.value = null;
								}}
							/>
						</div>

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "90%",
								height: "100%%",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
							}}>
							{formInput.menuPics.map((preview) => (
								<PhotoContainer preview={preview.url ? preview.url : pdfIcon} onChangePhotos={(action, id) => onChangePhotos(action, MENU_PICS, id)} type={MENU_PICS} id={preview.id} name={preview.name} />
							))}
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							height: "100%%",
							justifyContent: "center",
							alignItems: "center",
							position: "relative",
						}}>
						{props.alert.alertErr.foodPics ? (
							<Alert style={{ width: "50%", position: "relative" }} severity="error">
								Choose food pictures
							</Alert>
						) : null}
						<div className={classes.addSection}>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<h3
									style={{
										minWidth: "100px",
										color: "#707070",
										textAlign: "center",
										marginBottom: 0,
									}}>
									COVER PHOTOS
								</h3>
								<p
									style={{
										margin: 0,
										color: "#AAAAAA",
										fontSize: "12px",
										maxWidth: "300px",
										textAlign: "center",
									}}>
									We use the first picture you upload as the cover picture to your business, so upload something nice :)
								</p>
							</div>
							<IconButton className={classes.addBtn} disabled={disableFoodPics} onClick={onChooseHiddenFoodPics}>
								{formInput.foodPics.length !== 5 ? <AddIcon color="secondary" style={{ width: "30px", height: "30px" }}></AddIcon> : <CheckIcon color="secondary" style={{ width: "30px", height: "30px" }}></CheckIcon>}
							</IconButton>
							<input
								ref={hiddenFoodPicsRef}
								onChange={onChooseFoodPics}
								id="food-pics-chooser"
								type="file"
								multiple
								accept="image/*,application/pdf"
								style={{ display: "none" }}
								onClick={(event) => {
									event.target.value = null;
								}}
							/>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "90%",
								height: "100%%",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
							}}>
							{formInput.foodPics.map((preview) => (
								<PhotoContainer preview={preview.url ? preview.url : pdfIcon} onChangePhotos={(action, id) => onChangePhotos(action, FOOD_PICS, id)} type={FOOD_PICS} id={preview.id} name={preview.name} />
							))}
						</div>
					</div>
				</div>
			);
		}
	};
	return changeUiByViewPortWidth();
}
