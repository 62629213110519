import React, { useRef, useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";
import { VF_COLORS } from "../config";
import "./RestaurantInformation.css";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function RestaurantInformation(props) {
	const useStyles = makeStyles((theme) => ({
		container: {
			backgroundColor: "white",
			width: "100%",
			position: "relative",
			zIndex: "5",
			borderTopLeftRadius: "30px",
			borderTopRightRadius: "30px",
		},
		inputContainer: {
			backgroundColor: "white",
			width: "100%",
			position: "relative",
			zIndex: "5",
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		btnHide: {
			display: "none",
		},
		addLogoBtn: {
			position: "relative",
			borderRadius: "50%",
			left: "100px",
			bottom: "40px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "45px",
			height: "45px",
			border: "50%",
			zIndex: "2",
			border: "1px solid #85B858",
			backgroundColor: "white",
			"&:hover": {
				backgroundColor: "#E5E5E5",
			},
		},
		deleteLogoBtn: {
			position: "relative",
			borderRadius: "50%",
			bottom: "40px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "45px",
			height: "45px",
			border: "50%",
			zIndex: "2",
			border: "1px solid #85B858",
			backgroundColor: "white",
			"&:hover": {
				backgroundColor: "#E5E5E5",
			},
		},
		avatar: {
			position: "relative",
			height: "150px",
			width: "150px",
			backgroundColor: VF_COLORS.primary,
			borderRadius: "50%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		avatarErr: {
			position: "relative",
			height: "150px",
			width: "150px",
			backgroundColor: "red",
			borderRadius: "50%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		removeAvatarBg: {
			position: "relative",
			height: "150px",
			width: "150px",
			backgroundColor: "transparent",
			borderRadius: "50%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		avatarText: {
			fontSize: "10px",
			fontWeight: "bold",
			color: "white",
			textAlign: "center",
			fontSize: "18px",
		},
		inputFields: {
			display: "flex",
			flexDirection: "column",
			width: "30%",
			marginRight: "3%",
		},
		description: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
	}));

	const styles = {
		photoUploadContainer: {
			display: "flex",
			position: "relative",
			width: "100%",
			alignItems: "center",
		},
		choosePhotos: {
			backgroundColor: VF_COLORS.primary,
			color: "white",
			borderRadius: 25,
			width: 25,
		},

		choosePhotosDisabled: {
			backgroundColor: VF_COLORS.secondary,
			color: "white",
			borderRadius: 25,
			width: 25,
		},
	};

	const classes = useStyles();
	const hiddenLogoRef = useRef(null);
	const [isShown, setIsShown] = useState(false);
	const [description, setDescription] = useState("");
	const [restaurantName, setRestaurantName] = useState("");
	const [address, setAddress] = useState("");
	const [timezone, setTimezone] = useState(0);
	const [restaurantPhone, setRestaurantPhone] = useState("");

	// const {
	//   address,
	//   restaurantName,
	//   restaurantPhone,
	//   setRestaurantName,
	//   setAddress,
	//   setRestaurantPhone,
	// } = props;

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const onChooseLogo = (event) => {
		event.preventDefault();
		console.log(event.target.files[0]);
		const logo = {
			url: URL.createObjectURL(event.target.files[0]),
			id: Math.random(),
			path: event.target.value,
			file: event.target.files[0],
		};

		console.log(logo);
		props.handleInput("logo", logo);
	};

	useEffect(() => {
		console.log("useEffectFire");
		setRestaurantName(props.formInput.restaurant_name);
		setAddress(props.formInput.address);
		setRestaurantPhone(props.formInput.phone_number);
		setDescription(props.formInput.description);
	}, [props.dataIsLoaded]);

	const onChangePhotos = (action, type, id) => {
		console.log(`action ${action}, type: ${type}, id: ${id}`);
		let formInput = props.formInput;
		console.log(formInput.logo);
		switch ((action, type)) {
			case ("DELETE", "MENU_PICS"):
				props.handleInput("menuPics", [...formInput.menuPics.filter((pic) => pic.id !== id)]);
				break;
			case ("DELETE", "FOOD_PICS"):
				props.handleInput("foodPics", [...formInput.foodPics.filter((pic) => pic.id !== id)]);
				break;
			case ("DELETE", "LOGO"):
				props.handleInput("logo", null);
				break;
		}
	};

	const onChooseHiddenLogo = (event) => {
		hiddenLogoRef.current.click();
	};
	const disableLogoPic = props.formInput.logo && props.formInput.logo.url;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div className={classes.container}>
					<div
						style={{
							backgroundColor: "white",
							width: "100%",
							position: "relative",
							borderTopLeftRadius: "30px",
							borderTopRightRadius: "30px",
							zIndex: "5",
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							paddingLeft: "5%",
							paddingTop: "3%",
							paddingBottom: "1%",
						}}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
							}}>
							General
						</p>
					</div>
					<div className={classes.inputContainer}>
						<div
							className="restaurantLogo"
							style={{
								height: "150px",
								width: "150px",
								marginLeft: "5%",
								marginRight: "3%",
							}}>
							<div className={props.formInput.logo && props.formInput.logo.url ? classes.removeAvatarBg : props.alert.alertErr.logo ? classes.avatarErr : classes.avatar}>
								<input
									
									accept="image/*"
									ref={hiddenLogoRef}
									id="logo-chooser"
									type="file"
									onChange={onChooseLogo}
									// onClick={(event) => {
									//   event.target.value = null;
									// }}
									style={{ display: "none" }}
								/>
								{props.formInput.logo && props.formInput.logo.url ? (
									<Avatar
										src={props.formInput.logo.url}
										style={{
											position: "relative",
											top: 0,
											left: 0,
											height: "150px",
											width: "150px",
											backgroundSize: "cover",
										}}
									/>
								) : (
									<div>
										<div className={classes.avatarText}>{props.alert.alertErr.logo ? "LOGO" : "RESTAURANT"}</div>
										<div className={classes.avatarText}>{props.alert.alertErr.logo ? "IS" : "LOGO"}</div>
										<div className={classes.avatarText}>{props.alert.alertErr.logo ? "REQUIRED" : "HERE"}</div>
									</div>
								)}
							</div>
							<div>
								<button className={classes.addLogoBtn} id="basic-button" aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
									<CameraAltIcon
										style={{
											position: "absolute",
											color: "#85B858",
											height: "15px",
										}}
									/>
								</button>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button",
									}}>
									<MenuItem
										onClick={() => {
											handleClose();
											onChooseHiddenLogo();
										}}>
										<ListItemIcon>
											<ImageIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Upload image</ListItemText>
									</MenuItem>
									<MenuItem
										disabled={props.formInput.logo && props.formInput.logo.url ? false : true}
										onClick={() => {
											handleClose();
											onChangePhotos("DELETE", "LOGO", props.formInput.logo.id);
										}}>
										<ListItemIcon>
											<DeleteIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Remove</ListItemText>
									</MenuItem>
								</Menu>
							</div>
						</div>

						<div className={classes.inputFields}>
							<TextField
								id="standard-basic"
								label="Restaurant Name"
								variant="standard"
								value={restaurantName}
								onChange={(evt) => {
									setRestaurantName(evt.target.value);
								}}
								onBlur={() => {
									props.handleInput("restaurant_name", restaurantName);
									console.log(restaurantName);
								}}
								required
								error={!restaurantName && props.formInput.restaurantNameError}
								helperText={!restaurantName && props.formInput.restaurantNameError}
							/>
							<TextField
								id="standard-basic"
								label="Address"
								variant="standard"
								value={address}
								onChange={(evt) => {
									setAddress(evt.target.value);
								}}
								onBlur={() => props.handleInput("address", address)}
								required
								error={!address && props.formInput.addressError}
								helperText={!address && props.formInput.addressError}
							/>

							<TextField
								id="standard-basic" 
								label="Timezone"
								variant="standard"
								value={timezone}
								onChange={(evt) => {
									setTimezone(evt.target.value);
								}}
								onBlur={() => props.handleInput("timezone", timezone)}
								type="number"
							/>

							<TextField
								inputProps={{ maxLength: 15, minLength: 7 }}
								id="standard-basic"
								label="Phone Number"
								variant="standard"
								value={restaurantPhone}
								onChange={(evt) => setRestaurantPhone(evt.target.value)}
								onBlur={() => props.handleInput("phone_number", restaurantPhone)}
								required
								error={!restaurantPhone && props.formInput.phoneNumberError}
								helperText={!restaurantPhone && props.formInput.phoneNumberError}
							/>
							<div className="veganSwitch" style={{ position: "relative" }}>
								<FormControlLabel
									control={
										<Switch
											color="secondary"
											name="vegan"
											onChange={() => {
												props.formInput.vegan === false ? props.handleInput("vegan", props.tags[0]) : props.handleInput("vegan", false);
											}}
											checked={props.formInput.vegan !== false ? true : false}
										/>
									}
									label="100% Vegan"
								/>
							</div>
						</div>
						<div
							style={{
								width: "30%",
								display: "flex",
								alignItems: "center",
								paddingBottom: "40px",
							}}>
							<TextField
								required
								error={!description && props.formInput.descriptionError}
								helperText={!description && props.formInput.descriptionError}
								multiline="true"
								id="standard-basic"
								label="Description"
								variant="standard"
								className={classes.description}
								maxRows={5}
								value={description}
								onChange={(evt) => setDescription(evt.target.value)}
								onBlur={() => props.handleInput("description", description)}
							/>
						</div>
					</div>
				</div>
			);
		} else if (width <= 1199) {
			return (
				<div className={classes.container}>
					<div
						style={{
							backgroundColor: "white",
							width: "100%",
							position: "relative",
							borderTopLeftRadius: "30px",
							borderTopRightRadius: "30px",
							zIndex: "5",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "center",
							paddingTop: "3%",
							paddingBottom: "1%",
						}}>
						<p
							style={{
								margin: "0",
								fontSize: "24px",
								color: "#707070",
								letterSpacing: "0px",
								fontWeight: "600",
							}}>
							General
						</p>
						{props.alert.alertErr.logo ? <Alert severity="error">LOGO IS REQUIRED</Alert> : null}
					</div>
					<div className={classes.inputContainer} style={{ flexDirection: "column" }}>
						<div
							className="restaurantLogo"
							style={{
								height: "150px",
								width: "150px",
							}}>
							<div className={props.formInput.logo && props.formInput.logo.url ? classes.removeAvatarBg : classes.avatar}>
								<input required accept="image/*" ref={hiddenLogoRef} id="logo-chooser" type="file" onChange={onChooseLogo} style={{ display: "none" }} />
								{props.formInput.logo && props.formInput.logo.url ? (
									<Avatar
										src={props.formInput.logo.url}
										style={{
											position: "relative",
											top: 0,
											left: 0,
											height: "150px",
											width: "150px",
											backgroundSize: "cover",
										}}
									/>
								) : (
									<div>
										<div className={classes.avatarText}>LOGO</div>
										<div className={classes.avatarText}>IS</div>
										<div className={classes.avatarText}>REQUIRED</div>
									</div>
								)}
							</div>
							<div>
								<button className={classes.addLogoBtn} id="basic-button" aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
									<CameraAltIcon
										style={{
											position: "absolute",
											color: "#85B858",
											height: "15px",
										}}
									/>
								</button>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button",
									}}>
									<MenuItem
										onClick={() => {
											handleClose();
											onChooseHiddenLogo();
										}}>
										<ListItemIcon>
											<ImageIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Upload image</ListItemText>
									</MenuItem>
									<MenuItem
										disabled={props.formInput.logo && props.formInput.logo.url ? false : true}
										onClick={() => {
											handleClose();
											onChangePhotos("DELETE", "LOGO", props.formInput.logo.id);
										}}>
										<ListItemIcon>
											<DeleteIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Remove</ListItemText>
									</MenuItem>
								</Menu>
							</div>
						</div>

						<div className={classes.inputFields} style={{ margin: 0, width: "50%" }}>
							<TextField
								id="standard-basic"
								label="Restaurant Name"
								variant="standard"
								value={restaurantName}
								onChange={(evt) => {
									setRestaurantName(evt.target.value);
								}}
								onBlur={() => {
									props.handleInput("restaurant_name", restaurantName);
									console.log(restaurantName);
								}}
								required
								error={!restaurantName && props.formInput.restaurantNameError}
								helperText={!restaurantName && props.formInput.restaurantNameError}
								InputLabelProps={{ shrink: true }}
								style={{ marginTop: "50px" }}
							/>
							<TextField
								id="standard-basic"
								label="Address"
								variant="standard"
								value={address}
								onChange={(evt) => {
									setAddress(evt.target.value);
								}}
								onBlur={() => props.handleInput("address", address)}
								required
								error={!address && props.formInput.addressError}
								helperText={!address && props.formInput.addressError}
								InputLabelProps={{ shrink: true }}
								style={{ marginTop: "20px" }}
							/>
							<TextField
								inputProps={{ maxLength: 15, minLength: 7 }}
								id="standard-basic"
								label="Phone Number"
								variant="standard"
								value={restaurantPhone}
								onChange={(evt) => setRestaurantPhone(evt.target.value)}
								onBlur={() => props.handleInput("phone_number", restaurantPhone)}
								required
								error={!restaurantPhone && props.formInput.phoneNumberError}
								helperText={!restaurantPhone && props.formInput.phoneNumberError}
								InputLabelProps={{ shrink: true }}
								style={{ marginTop: "20px" }}
								type="tel"
							/>
						</div>
						<div
							style={{
								width: "50%",
								display: "flex",
								alignItems: "center",
								paddingBottom: "45px",
							}}>
							<TextField
								required
								error={!description && props.formInput.descriptionError}
								helperText={!description && props.formInput.descriptionError}
								multiline="true"
								id="standard-basic"
								label="Description"
								variant="standard"
								className={classes.description}
								maxRows={5}
								value={description}
								onChange={(evt) => setDescription(evt.target.value)}
								onBlur={() => props.handleInput("description", description)}
								InputLabelProps={{ shrink: true }}
								style={{ marginTop: "20px", width: "100%" }}
							/>
						</div>
						<div className="veganSwitch" style={{ position: "relative" }}>
							<FormControlLabel control={<Switch color="secondary" name="vegan" onChange={() => props.handleInput("vegan", !props.formInput.vegan)} checked={props.formInput.vegan} />} label="100% Vegan" />
						</div>
					</div>
				</div>
			);
		}
	};
	return changeUiByViewPortWidth();
}
