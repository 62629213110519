import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { VF_COLORS } from "../config";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function RestaurantOtherFeatures(props) {
	const useStyles = makeStyles((theme) => ({
		deliveryOptions: {
			display: "flex",
			paddingBottom: "5%",
			width: "90%",
		},
		url: {
			height: "40px",
			width: "100%",
		},
		hideUrl: {
			display: "none",
		},
		avatar: {
			marginRight: "20px",
			marginLeft: "10px",
		},
		label: {
			width: "100px",
		},
	}));
	const classes = useStyles();

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { height, width } = useWindowDimensions();

	const changeUiByViewPortWidth = () => {
		if (width > 1199) {
			return (
				<div className={classes.deliveryOptions}>
					<div className={classes.avatar} style={{ marginRight: "20px" }}>
						<Avatar src={props.delivery.logo} id={props.delivery.name}></Avatar>
					</div>
					<div className={classes.switch}>
						<FormControlLabel classes={{ label: classes.label }} control={<Switch color="secondary" name={props.delivery.name} id={props.delivery.name} onChange={() => props.handleInput(props.delivery.value, !props.checked)} checked={props.checked} />} label={props.delivery.name} />
					</div>
					<div className={props.checked ? classes.url : classes.hideUrl}>
						<TextField size="small" onChange={(event) => props.onURLChange(event.target.value)} value={props.url} id="standard-basic" label="URL" variant="standard" error={!props.url && props.errorMsg} helperText={!props.url && props.errorMsg} style={{ width: "100%", bottom: "25%" }} />
					</div>
				</div>
			);
		} else if (width <= 1199) {
			return (
				<div
					className={classes.deliveryOptions}
					style={{
						flexDirection: "column",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<div style={{ display: "flex" }}>
						<div className={classes.avatar} style={{ marginRight: "20px" }}>
							<Avatar src={props.delivery.logo} id={props.delivery.name}></Avatar>
						</div>
						<div className={classes.switch}>
							<FormControlLabel classes={{ label: classes.label }} control={<Switch color="secondary" name={props.delivery.name} id={props.delivery.name} onChange={() => props.handleInput(props.delivery.value, !props.checked)} checked={props.checked} />} label={props.delivery.name} />
						</div>
					</div>

					<div className={props.checked ? classes.url : classes.hideUrl} style={{ width: "100%", marginTop: "10px" }}>
						<TextField size="small" onChange={(event) => props.onURLChange(event.target.value)} value={props.url} url={props.formInput.justEatURL} id="standard-basic" label="URL" variant="standard" style={{ width: "100%", bottom: "25%" }} />
					</div>
				</div>
			);
		}
	};

	return changeUiByViewPortWidth();
}
